import React, { useEffect, useState } from 'react'
import nagap from "../../Images/bok-photo4.jpg";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import TechStartupsNagalandCard from "../../Component/TechStartupsNagalandCard"
import ScholarshipsNagalandCard from "../../Component/ScholarshipsNagalandCard";
import gfhf from "../../Images/history.jpg"
import BreadcrumbsHeader from '../../Component/BreadcrumbsHeader';
import HomeService from '../../Service/HomeService';
import toast from "react-hot-toast"

function EducationCareer({ singleThematic }) {
    const [eduTabData, setEduTabData] = useState([]);
    const [headData, setHeadData] = useState("")
    const [bodySection, setBodySection] = useState({});

    const [eduAllData, setEduALlData] = useState([]);

    const [sequenceData, setSequenceData] = useState([])

    console.log("singleThematic", singleThematic);



    useEffect(() => {

        // setHeadData(sequenceData[0]?._id)
        fetchAllEduData()

    }, [singleThematic]);

    useEffect(() => {
        setEduTabData(sequenceData[0]?.data);
        sequenceData.length !== 0 && EducationData(sequenceData[0]?._id);
    }, [sequenceData])

    useEffect(() => {
        EduSequenceData()
    }, [eduAllData])

    const EduSequenceData = () => {

        console.log("ALLDATA", eduAllData, singleThematic);
        let arr = [];
        eduAllData && eduAllData.forEach((ele) => {
            singleThematic?.data.forEach((item) => {
                if (item?._id === ele) {
                    arr.push(item);
                }
            })
        })
        setSequenceData(arr)

        console.log("SEQUENCEDATA", arr);

    }



    const fetchAllEduData = () => {
        let arr = [];
        HomeService.ViewAllEducationStartupType("education-and-career")
            .then((res) => {
                console.log("Response Add EducationType", res);
                if (res && res.status) {
                    // setBodySection(res?.data)
                    res?.data?.forEach(ele => {
                        arr.push(ele?.typeName)
                    });

                    setEduALlData(arr);
                } else {
                    toast.error(res?.message)
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }


    console.log("ALLDATA", eduAllData);
    console.log(("ALLUNSEQDATA", singleThematic));




    const EducationData = (name) => {
        let data = {
            name: name
        }
        HomeService.ViewStartupBody(singleThematic?.slug, data)
            .then((res) => {
                console.log("Response Add StartupsType", res);
                if (res && res.status) {
                    setBodySection(res?.data)
                } else {
                    toast.error(res?.message)
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
      <>
        <div className="nagacontainer">
          <div className="row mt-2">
            <BreadcrumbsHeader
              breadArr={[
                {
                  name: 'Thematic Content',
                  path: '/thematic',
                },
                {
                  name: singleThematic?.name,
                  path: '',
                },
                // "Thematic Content", singleThematic?.name
              ]}
            />
          </div>
        </div>
        <section className="StarupsNagaland_page">
          <div className="nagacontainer">
            <div className="head_line">
              <h2>{singleThematic?.name}</h2>
            </div>

            <div className="row">
              <div className="col-lg-5 col-md-6 col-12">
                <div className="nagaimg">
                  <img src={singleThematic?.img} className="img-fluid" alt="" />
                </div>
              </div>
              <div className="col-lg-7 col-md-6 col-12">
                <div className="nagatext">
                  {/* <p>{singleThematic?.summary}</p> */}
                  <p>
                    {/* {singleThematic?.summary} */}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: singleThematic?.summary,
                      }}
                    />
                  </p>
                </div>
              </div>
            </div>

            <div class="Startups_tabarea Scholorships_tabarea">
              <Tabs>
                <TabList className="tabList">
                  {sequenceData?.map((item, i) => (
                    <Tab
                      onClick={() => {
                        EducationData(item?._id);
                        setEduTabData(item?.data);
                        setHeadData(item?._id);
                      }}
                    >
                      {item?._id}
                    </Tab>
                  ))}

                  {/* <Tab>Scholorships</Tab>
                                <Tab>Fellowships</Tab>
                                <Tab>Other opportunities</Tab>
                                <Tab>Question Banks</Tab>
                                <Tab>Exploring other careers with people</Tab> */}
                </TabList>

                {sequenceData?.map((item, i) => (
                  <TabPanel>
                    <div className="content_wrap">
                      {/* <div className='Startups_leftcontent'>
                                            <ScholarshipsNagalandCard
                                                headData={headData}
                                                body={bodySection}
                                            />
                                        </div> */}
                      <div className="Startups_rightcontent">
                        {eduTabData &&
                          eduTabData?.map((item, i) => (
                            <div className="cradbox_wrap">
                              <a target="_blank" href={item?.link} className="bookimg">
                                <img src={item?.image} className="img-fluid" alt="" />
                              </a>
                              <div className="detailss">
                                <div className="name">
                                  <h4>
                                    <a target="_blank" href={item?.link}>
                                      {item?.name}
                                    </a>
                                  </h4>
                                </div>
                                <div className="text">
                                  <p>{item?.description}</p>
                                </div>
                                {/* <div className="morebtn_">
                                  <a className="btn" target="_blank" href={item?.link}>
                                    {item?.link}
                                  </a>
                                </div> */}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </TabPanel>
                ))}
              </Tabs>
            </div>
          </div>
        </section>
      </>
    );
}

export default EducationCareer