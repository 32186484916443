import React from 'react'
import { useDispatch } from 'react-redux'
import {Link} from 'react-router-dom'
import { set_archives_state } from '../../redux/reducers/archivesSlice';
const Archive = () => {
  const dispatch = useDispatch();
  return (
    <>
      <section className='archive_section' style={{
        marginTop:'50px'
      }}>
        <div className='nagacontainer conFlu'>
            <h3 style={{marginBottom:"30px"}} className='feco'>Archives</h3>
            <p style={{marginBottom:"70px",lineHeight:'25px'}}>
            {/* The <strong>Nagaland State Archives</strong> serves as the primary repository for historical records, government documents, and cultural manuscripts of the state. Managed and maintained by the <strong>Department of Art and Culture, Government of Nagaland,</strong>  it plays a crucial role in preserving the rich heritage, administrative history, and socio-political evolution of the Naga people. */}
            The Nagaland State Archives serves as the primary repository for historical records, government documents, and cultural manuscripts of the state. Managed and maintained by the Department of Art and Culture, Government of Nagaland, it plays a crucial role in preserving the rich heritage, administrative history, and socio-political evolution of the Naga people.

            </p>
             <div
                        className="seemore_div"
                      // data-aos="fade-up"
                      // data-aos-duration="3000"
                      >
                        <Link to="/category-details/art-culture-and-literature" onClick={() => {
                          dispatch(set_archives_state());
                        }}>SEE ALL ITEMS</Link>
                      </div>
        </div>
      </section>
    </>
  )
}

export default Archive
