import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import BreadcrumbsHeader from "../../Component/BreadcrumbsHeader";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import ReactPlayer from "react-player";
import "react-tabs/style/react-tabs.css";
import h from "../../Images/history.jpg";
import c from "../../Images/list1.jfif";
import audio from "../../Images/record.png";
import video from "../../Images/videos/Intro-Short-Video-Edinboro-University.mp4";
import Pagination from "../../Component/Pagination";
import demoImg from "../../Images/demoImage.jpg";
import sandDemo from "../../Images/sandDemoPic.jpg";
import HistoryLeftCard from "../../Component/HistoryLeftCard";
import HttpClient from "../../utils/HttpClient";
import { RotatingLines } from "react-loader-spinner";
import HomeService from "../../Service/HomeService";
import { async } from "q";
import NoDataFound from "../../Helper/NoDataFound";
import { useDispatch, useSelector } from "react-redux";
import { getColCat } from "../../redux/reducers/CollectionCategorySlice";
import { toast } from "react-hot-toast";
import SkeletonLoader from "../../Component/SkeletonLoader";
import NoImageAvialbe from "../../Images/noimage.jpg";
import NoDataFoundImage from "../../Images/nodatafound.png";
import Pegination from '../../Component/Pagination'
const SearchCollection = () => {
  const { colCatData } = useSelector((state) => state.colCatReducer);
  const dispatch = useDispatch();
  const { searchquery } = useParams();


  const [page, setPage] = useState(1);
  const numberOfDataInEachPage = 15;


  let typeName = [
    "books",
    "documents",
    "audios",
    "videos",
    "photographs",
    "papers and journals",
    "reports",
  ];

  const [playAudio, setPlayAudio] = useState(false);
  const [audioData, setAudioData] = useState({});

  const [videoPlay, setVideoPlay] = useState(false);
  const [videoLinkPlay, setVideoLinkPlay] = useState(false);
  const [videosData, setVideosData] = useState({});

  const [image, setImage] = useState([]);
  const [select, setSelect] = useState("");
  const [categoryData, setCategoryData] = useState([]);
  const [currType, setCurrType] = useState("Books");
  const [singleColCat, setSingleColCat] = useState({});

  const [AllSearchData, setAllSearchData] = useState([]);
  const [collType, setCollType] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [data, setData] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  console.log("filterData", location.state);

  const handleType = async (name) => {
    AllSearchData.map((item, data) => {
      console.log("All_SearchData", item);
      if (item._id === name) {
        setFilterData(item?.data);
      }
      console.log("FILTWER", filterData);
    });
  };

  useEffect(() => {
    fetchSearch();
    // if (location?.pathname === "/search-collection") {
    //   console.log("SearchData", location?.state);
    //   setAllSearchData(location?.state);
    //   CollectionType(location?.state);
    //   console.log("LOCATIONSEARCH", location?.state);
    // }
    // console.log("locationDRT", location.state[0]._id);
    // setFilterData(searchquery[0].data);
  }, []);

  const fetchSearch = () => {
    let arr = [];
    HomeService.SearhSection({ searchTag: searchquery })
      .then((res) => {
        console.log("RESPONSE", res);
        if (res && res?.status) {
          if (res?.data.length === 0) {
            // toast.error("NO Collection Matched")
            // setSearchValue("");
          } else {
            typeName.forEach((ele) => {
              res?.data.forEach((item) => {
                if (item?._id === ele) {
                  arr.push(item);
                }
              });
            });

            console.log("RESPONSEData", res?.data);
            console.log("SEQUENCEARR", arr);
            setAllSearchData(arr);
            CollectionType(arr);
            let allData = [];
            arr.forEach((element) => {
              allData.push(...element.data);
            });
            let updatedData = [{ _id: "", data: allData }, ...arr];
            console.log("allData", allData);
            setData(updatedData);
            // console.log("SEARCHDATA" , res?.data);
          }
        } else {
          // toast.error(res?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
        window.scrollTo({
          top: "0",
          left: "0",
          behavior: "instant",
        });
      });
  };

  const CollectionType = (data) => {
    console.log("AllSearchg", AllSearchData);
    let arr = [];
    data?.map((item, index) => {
      arr.push(item?._id);
    });
    setCollType(arr);
    console.log("ARRR", arr);
  };

  return (
    <>
      <div className="tabsPart mt-5 mb-5">
        <div className="nagacontainer">
          <section id="history">
            <div className="history_body">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                  <h3 style={{ textAlign: "center", padding: "12px" }}>
                    Your Search Results
                  </h3>
                  {loading && (
                    <>
                      <SkeletonLoader />
                      <SkeletonLoader />
                      <SkeletonLoader />
                      <SkeletonLoader />
                    </>
                  )}
                  <Tabs>
                    <TabList className="tabListTag searchCollectionTab">
                      {console.log("DATA", data)}
                      {data.map((ele) => (
                        <Tab
                          disabled={ele?._id == "All"}
                          onClick={() => {handleType(ele); setPage(1)}}
                        >
                          <a className="">
                            <span style={{ textTransform: "capitalize" }}>
                              {ele?._id}
                            </span>
                          </a>
                        </Tab>
                      ))}
                    </TabList>

                    {data.map((item) => {
                      console.log("Item", item);
                      return (
                        <TabPanel>
                          {item?._id === "videos" ? (
                            <>
                            <div className="row">
                              <div
                                style={{ margin: "auto", cursor: "pointer" }}
                              ></div>
                              <div className="col-xl-12 col-lg-12 col-md-9 col-12">
                                {item?.data?.slice(
                                    page * numberOfDataInEachPage -
                                      numberOfDataInEachPage,
                                    page * numberOfDataInEachPage
                                  ).map((ele) => (
                                  <div
                                    className="history_right"
                                    onClick={() => {
                                      setVideosData(ele);
                                      if (ele?.uploadCollection)
                                        setVideoPlay(true);
                                      else if (ele?.link)
                                        setVideoLinkPlay(true);
                                      else
                                        toast.error(
                                          "Not any uploadCollection & link is Available"
                                        );
                                    }}
                                  >
                                    <div className="right_list">
                                      <div className="list-item">
                                        <div className="listimg_wrapper video_list_wrapper">
                                          {/* <img src='https://cdn.pixabay.com/photo/2018/08/26/23/55/woman-3633737_1280.jpg' /> */}
                                          {/* <img src={ele?.collectionImage?ele?.collectionImage:NoImageAvialbe} /> */}
                                          {ele?.uploadCollection ? (
                                            <div
                                              className="video_"
                                              style={{
                                                height: "100%",
                                                width: "100%",
                                              }}
                                            >
                                              <video poster controls>
                                                <source
                                                  src={ele?.uploadCollection}
                                                  type="video/mp4"
                                                />
                                              </video>
                                            </div>
                                          ) : (
                                            <div>
                                              <ReactPlayer
                                                className="react-player"
                                                controls
                                                width="14rem"
                                                height="9rem"
                                                // playing={true}
                                                url={ele.link}
                                                config={{
                                                  youtube: {
                                                    playerVars: { showinfo: 1 },
                                                  },
                                                  facebook: {
                                                    appId: "12345",
                                                  },
                                                }}
                                              />
                                            </div>
                                          )}
                                        </div>
                                        <div className="list_text">
                                          <a href="#">
                                            <h6>{ele.title}</h6>
                                          </a>
                                          {/* <p>14/06/2023</p> */}
                                          {/* <p>{ele.desc}</p> */}

                                          <div
                                            style={{
                                              maxWidth: "300px",
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems: "center",
                                              flexWrap: "wrap",
                                            }}
                                          >
                                            <p>
                                              <a href="#">View Summary</a>
                                            </p>
                                            <p
                                              className="italic"
                                              style={{
                                                textTransform: "capitalize",
                                              }}
                                            >
                                              Type: {ele?.type}
                                            </p>
                                          </div>
                                          <p>
                                            <span className="citation">
                                              Source/Author: {ele.author}
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                      {/* </Link> */}
                                    </div>
                                  </div>
                                ))}

                                {videoPlay && (
                                  <section className="videopopup_area">
                                    <div
                                      className="wrapbox"
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        height: "70%",
                                        width: "60%",
                                      }}
                                    >
                                      <video
                                        controls
                                        autoPlay
                                        loop
                                        width="320"
                                        height="240"
                                      >
                                        <source
                                          src={videosData?.uploadCollection}
                                          type="video/mp4"
                                        />
                                      </video>
                                      <button
                                        className="close_btn"
                                        onClick={() => {
                                          setVideoPlay(false);
                                        }}
                                      >
                                        <i className="fa-solid fa-xmark"></i>
                                      </button>
                                      <h7
                                        dangerouslySetInnerHTML={{
                                          __html: videosData?.summery,
                                        }}
                                      />
                                    </div>
                                  </section>
                                )}

                                {videoLinkPlay && (
                                  <section className="videopopup_area">
                                    <div
                                      className="wrapbox"
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        height: "70%",
                                        width: "60%",
                                      }}
                                    >
                                      <div
                                        style={{
                                          height: "100%",
                                          width: "100%",
                                        }}
                                      >
                                        <ReactPlayer
                                          className="react-player"
                                          controls
                                          width="100%"
                                          height="100%"
                                          playing={true}
                                          url={videosData?.link}
                                          config={{
                                            youtube: {
                                              playerVars: {
                                                showinfo: 1,
                                              },
                                            },
                                            facebook: {
                                              appId: "12345",
                                            },
                                          }}
                                        />
                                      </div>
                                      <button
                                        className="close_btn"
                                        onClick={() => setVideoLinkPlay(false)}
                                      >
                                        <i className="fa-solid fa-xmark"></i>
                                      </button>
                                      <h7
                                        dangerouslySetInnerHTML={{
                                          __html: videosData?.summery,
                                        }}
                                      />
                                    </div>
                                  </section>
                                )}
                              </div>
                            </div>
                            {
                              item?.data && item?.data?.length > numberOfDataInEachPage && <Pagination page={page} setPage={setPage}
                              numberOfPages={Math.ceil(
                                item?.data.length / numberOfDataInEachPage
                              )}/>
                            }
                            </>
                          ) : item?._id === "audios" ? (
                            <>
                            <div className="row" style={{ cursor: "pointer" }}>
                              <div style={{ margin: "auto" }}></div>
                              <div className="col-xl-12 col-lg-12 col-md-9 col-12">
                                {item?.data?.slice(
                                    page * numberOfDataInEachPage -
                                      numberOfDataInEachPage,
                                    page * numberOfDataInEachPage
                                  ).map((ele) => (
                                  <div
                                    className="history_right"
                                    onClick={() => {
                                      if (
                                        ele?.uploadCollection == "" &&
                                        ele?.link == ""
                                      ) {
                                        toast.error(
                                          "There is no uploadCollection and link is available"
                                        );
                                      } else {
                                        setAudioData(ele);
                                        setPlayAudio(true);
                                      }
                                    }}
                                  >
                                    <div className="right_list">
                                      {/* <Link to={`/types-details/${ele?.slug}`}> */}
                                      <div className="list-item">
                                        <div className="listimg_wrapper">
                                          <img
                                            src={
                                              ele?.collectionImage
                                                ? ele?.collectionImage
                                                : NoImageAvialbe
                                            }
                                          />
                                        </div>
                                        <div className="list_text">
                                          <a href="#">
                                            <h6>{ele.title}</h6>
                                          </a>

                                          <p>{ele.desc}</p>

                                          <div
                                            style={{
                                              maxWidth: "300px",
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems: "center",
                                              flexWrap: "wrap",
                                            }}
                                          >
                                            <p>
                                              <a href="#">View Summary</a>
                                            </p>
                                            <p
                                              className="italic"
                                              style={{
                                                textTransform: "capitalize",
                                              }}
                                            >
                                              Type: {ele?.type}
                                            </p>
                                          </div>
                                          <p>
                                            <span className="citation">
                                              Source/Author: {ele.author}
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                      {/* </Link> */}
                                    </div>
                                  </div>
                                ))}

                                {playAudio && (
                                  <section className="audiopopup_area">
                                    <div
                                      className="wrapbox"
                                      style={{
                                        height: "20%",
                                        width: "60%",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <audio controls autoPlay loop>
                                        <source
                                          src={
                                            audioData?.uploadCollection
                                              ? audioData?.uploadCollection
                                              : audioData?.link
                                          }
                                          type="audio/mpeg"
                                        />
                                      </audio>

                                      <button
                                        className="close_btn"
                                        onClick={() => setPlayAudio(false)}
                                      >
                                        <i className="fa-solid fa-xmark"></i>
                                      </button>
                                    </div>
                                  </section>
                                )}
                              </div>
                            </div>
                            
                            {
                              item?.data && item?.data?.length > numberOfDataInEachPage && <Pagination page={page} setPage={setPage}
                              numberOfPages={Math.ceil(
                                item?.data.length / numberOfDataInEachPage
                              )}/>
                            }
                            
                            </>
                          ) : (
                            <>
                            <div className="row">
                              <div style={{ margin: "auto" }}></div>
                              <div className="col-xl-12 col-lg-12 col-md-9 col-12">
                                {item?.data?.slice(
                                    page * numberOfDataInEachPage -
                                      numberOfDataInEachPage,
                                    page * numberOfDataInEachPage
                                  ).map((ele) =>
                                  ele.type === "videos" ? (
                                    <div
                                      className="history_right"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setVideosData(ele);
                                        if (ele?.uploadCollection)
                                          setVideoPlay(true);
                                        else if (ele?.link)
                                          setVideoLinkPlay(true);
                                        else
                                          toast.error(
                                            "There is no uploadCollection & link is Available"
                                          );
                                      }}
                                    >
                                      <div className="right_list">
                                        {/* <Link to={`/types-details/${ele?.slug}`}> */}
                                        <div className="list-item">
                                          <div className="listimg_wrapper video_list_wrapper">
                                            {/* <img src={ele?.collectionImage?ele?.collectionImage:NoImageAvialbe} /> */}
                                            {ele?.uploadCollection ? (
                                              <div
                                                className="video_"
                                                style={{
                                                  height: "100%",
                                                  width: "100%",
                                                }}
                                              >
                                                <video poster controls>
                                                  <source
                                                    src={ele?.uploadCollection}
                                                    type="video/mp4"
                                                  />
                                                </video>
                                              </div>
                                            ) : (
                                              <div>
                                                <ReactPlayer
                                                  className="react-player"
                                                  controls
                                                  width="14rem"
                                                  height="9rem"
                                                  // playing={true}
                                                  url={ele.link}
                                                  config={{
                                                    youtube: {
                                                      playerVars: {
                                                        showinfo: 1,
                                                      },
                                                    },
                                                    facebook: {
                                                      appId: "12345",
                                                    },
                                                  }}
                                                />
                                              </div>
                                            )}
                                          </div>
                                          <div className="list_text">
                                            <a href="#">
                                              <h6>{ele.title}</h6>
                                            </a>

                                            {/* <p>{ele.desc}</p> */}

                                            <div
                                              style={{
                                                maxWidth: "300px",
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                flexWrap: "wrap",
                                              }}
                                            >
                                              <p>
                                                <a href="#">View Summary</a>
                                              </p>
                                              <p
                                                className="italic"
                                                style={{
                                                  textTransform: "capitalize",
                                                }}
                                              >
                                                Type: {ele?.type}
                                              </p>
                                            </div>
                                            <p>
                                              <span className="citation">
                                                Source/Author: {ele.author}
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                        {/* </Link> */}
                                      </div>
                                    </div>
                                  ) : ele?.type === "audios" ? (
                                    <div
                                      className="history_right"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        if (
                                          ele?.uploadCollection == "" &&
                                          ele?.link == ""
                                        ) {
                                          toast.error(
                                            "There is no uploadCollection and link is available"
                                          );
                                        } else {
                                          setAudioData(ele);
                                          setPlayAudio(true);
                                        }
                                      }}
                                    >
                                      <div className="right_list">
                                        {/* <Link to={`/types-details/${ele?.slug}`}> */}
                                        <div className="list-item">
                                          <div className="listimg_wrapper">
                                            <img
                                              src={
                                                ele?.collectionImage
                                                  ? ele?.collectionImage
                                                  : NoImageAvialbe
                                              }
                                            />
                                          </div>
                                          <div className="list_text">
                                            <a href="#">
                                              <h6>{ele.title}</h6>
                                            </a>

                                            <p>{ele.desc}</p>

                                            <div
                                              style={{
                                                maxWidth: "300px",
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                flexWrap: "wrap",
                                              }}
                                            >
                                              <p>
                                                <a href="#">View Summary</a>
                                              </p>
                                              <p
                                                className="italic"
                                                style={{
                                                  textTransform: "capitalize",
                                                }}
                                              >
                                                Type: {ele?.type}
                                              </p>
                                            </div>
                                            <p>
                                              <span className="citation">
                                                Source/Author: {ele.author}
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                        {/* </Link> */}
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="history_right">
                                      <div className="right_list">
                                        <Link
                                          to={`/types-details/${ele?.slug}`}
                                        >
                                          <div className="list-item">
                                            <div className="listimg_wrapper">
                                              <img
                                                src={
                                                  ele?.collectionImage
                                                    ? ele?.collectionImage
                                                    : NoImageAvialbe
                                                }
                                              />
                                            </div>
                                            <div className="list_text">
                                              <a href="#">
                                                <h6>{ele.title}</h6>
                                              </a>

                                              {/* <p>{ele.desc}</p> */}

                                              <div
                                                style={{
                                                  maxWidth: "300px",
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                  alignItems: "center",
                                                  flexWrap: "wrap",
                                                }}
                                              >
                                                <p>
                                                  <a href="#">View Summary</a>
                                                </p>
                                                <p
                                                  className="italic"
                                                  style={{
                                                    textTransform: "capitalize",
                                                  }}
                                                >
                                                  Type: {ele?.type}
                                                </p>
                                              </div>
                                              <p>
                                                <span className="citation">
                                                  Source/Author: {ele.author}
                                                </span>
                                              </p>
                                            </div>
                                          </div>
                                        </Link>
                                      </div>
                                    </div>
                                  )
                                )}

                                {videoPlay && (
                                  <section className="videopopup_area">
                                    <div
                                      className="wrapbox"
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        height: "70%",
                                        width: "60%",
                                      }}
                                    >
                                      <video
                                        controls
                                        autoPlay
                                        loop
                                        width="320"
                                        height="240"
                                      >
                                        <source
                                          src={videosData?.uploadCollection}
                                          type="video/mp4"
                                        />
                                      </video>
                                      <button
                                        className="close_btn"
                                        onClick={() => {
                                          setVideoPlay(false);
                                        }}
                                      >
                                        <i className="fa-solid fa-xmark"></i>
                                      </button>
                                      <h7
                                        dangerouslySetInnerHTML={{
                                          __html: videosData?.summery,
                                        }}
                                      />
                                    </div>
                                  </section>
                                )}

                                {videoLinkPlay && (
                                  <section className="videopopup_area">
                                    <div
                                      className="wrapbox"
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        height: "70%",
                                        width: "60%",
                                      }}
                                    >
                                      <div
                                        style={{
                                          height: "100%",
                                          width: "100%",
                                        }}
                                      >
                                        <ReactPlayer
                                          className="react-player"
                                          controls
                                          width="100%"
                                          height="100%"
                                          playing={true}
                                          url={videosData?.link}
                                          config={{
                                            youtube: {
                                              playerVars: {
                                                showinfo: 1,
                                              },
                                            },
                                            facebook: {
                                              appId: "12345",
                                            },
                                          }}
                                        />
                                      </div>
                                      <button
                                        className="close_btn"
                                        onClick={() => setVideoLinkPlay(false)}
                                      >
                                        <i className="fa-solid fa-xmark"></i>
                                      </button>
                                      <h7
                                        dangerouslySetInnerHTML={{
                                          __html: videosData?.summery,
                                        }}
                                      />
                                    </div>
                                  </section>
                                )}

                                {playAudio && (
                                  <section className="audiopopup_area">
                                    <div
                                      className="wrapbox"
                                      style={{
                                        height: "20%",
                                        width: "60%",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <audio controls autoPlay loop>
                                        <source
                                          src={
                                            audioData?.uploadCollection
                                              ? audioData?.uploadCollection
                                              : audioData?.link
                                          }
                                          type="audio/mpeg"
                                        />
                                      </audio>

                                      <button
                                        className="close_btn"
                                        onClick={() => setPlayAudio(false)}
                                      >
                                        <i className="fa-solid fa-xmark"></i>
                                      </button>
                                    </div>
                                  </section>
                                )}
                              </div>
                            </div>
                            {item?.data &&
                        item?.data.length > numberOfDataInEachPage && (
                          <Pagination
                            page={page}
                            setPage={setPage}
                            numberOfPages={Math.ceil(
                              item?.data.length / numberOfDataInEachPage
                            )}
                          />
                        )}
                            </>
                            
                          )}
                        </TabPanel>


                      );
                    })}
                  </Tabs>

                  {data.length === 0 && loading === false && (
                    <div
                      style={{
                        height: "400px",
                        flexDirection: "column",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={NoDataFoundImage}
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "contain",
                        }}
                      />
                      <p>No search result found for {searchquery}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>


      {/* <Pegination/> */}
    </>
  );
};

export default SearchCollection;
