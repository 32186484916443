import React from 'react'
import { Link, useLocation } from 'react-router-dom';

export default function BreadcrumbsHeader(props) {
    const {
        breadArr = ["home"]
    } = props;

    console.log("breadArr",breadArr);

    // const breadArr = ["Home", "collection", "theme"]

    return (
        <>

            <div className='breadcrumbs-header'>
                <div className='container-fluid'>
                    <nav>
                        {/* {home && <Link to="/">Home</Link>}
                        {collection && <Link to="/collection" style={{ color: '#000' }}><i class="fa-solid fa-angle-right"></i>{menu}</Link>} */}

                        {/* {option && <Link to="/collection"><i class="fa-solid fa-angle-right"></i>{collectionName} </Link>}
                        {title && <a href="#">All Items </a>}
                        {types && <a href="#" style={{ color: '#000' }}><i class="fa-solid fa-angle-right"></i>{currType}</a>} */}
                            {console.log("BreadArr", breadArr)}
                        {
                            breadArr?.map((item, i) =>
                                <Link to={item?.path} style={breadArr.length - 1 == i ? { color: '#000' } : {}}>
                                    {item?.name}
                                    {breadArr.length - 1 != i &&
                                        <i class="fa-solid fa-angle-right"></i>
                                    }
                                </Link>
                            )
                        }
                    </nav>
                </div>
            </div>
        </>
    )
}
