import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import a from "../../Images/c1.png";
import b from "../../Images/c2.png";
import c from "../../Images/c3.png";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import pattern from "../../Images/pattern.jpg";
import AOS from "aos";
import "aos/dist/aos.css";

const FeatureItesHome = ({ featureData, themeCatData }) => {
  console.log("themeDatadf", themeCatData);

  // aos animation
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <div>
      <section
        className="feature featuredsec">
        <div className="nagacontainer conFlu">
          <div className="">
            <div>
              <h3 className="feco">Featured Items</h3>
              <p className="detailss">Dive into Nagaland's rich tapestry of history, literature, and culture through our collection. Explore captivating narratives of the past, delve into literary treasures, and immerse yourself in the vibrant cultural heritage of Nagaland.</p>
            </div>
            <figure className="ptrnFig">
              <img src={pattern} alt="" />
            </figure>
          </div>

          <div className="collection_list">
            <div
              className="collection_top"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <Swiper
                autoplay={false}
                autoplaySpeed={2000}
                spaceBetween={15}
                slidesPerView={1}
                onSlideChange={() => console.log("slide change")}
                onSwiper={(swiper) => console.log(swiper)}
                navigation={true}
                modules={[Navigation]}
                breakpoints={{
                  480: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                  1200: {
                    slidesPerView: 5,
                    spaceBetween: 20,
                  },
                }}
                className="myPerSwiper"
              >
                {featureData?.map((item, i) => (
                  <SwiperSlide>
                    <Link
                      to={`/types-details/${item?.slug}`}
                      className="imgfc"
                      key={i}
                    >
                      <figure className="imgFig imgONEffect">
                        <img src={item?.collectionImage} alt="" />
                        <div className="imgOnHover">
                          <p>{item?.content}</p>
                        </div>
                      </figure>
                      <div className="box">
                        <h5 className="text">{item?.title}</h5>
                        {/* <p className='para'>Author: <span>{item?.author}</span></p> */}
                        <p className="para2">
                          Content Type: <span>{item?.type}</span>
                        </p>
                      </div>
                    </Link>
                  </SwiperSlide>
                ))}
                {themeCatData?.map((item, i) => (
                  <SwiperSlide>
                    <Link to={`/category-details/${item?.categoryData?.slug}`}>
                      <figure className="imgFig imgONEffect">
                        <img src={item?.image} alt="" />
                        <div className="imgOnHover">
                          <p>{item?.content}</p>
                        </div>
                      </figure>
                      <div className="box">
                        <h5 className="text">{item?.title}</h5>
                        {/* <p className='para'>Author: <span>{item?.author}</span></p> */}
                        <p className="para2">
                          Content Type: <span>Photographs</span>
                        </p>
                      </div>
                    </Link>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
          <div
            className="seemore_div"
          // data-aos="fade-up"
          // data-aos-duration="3000"
          >
            <Link to="/collection">SEE ALL ITEMS</Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FeatureItesHome;
