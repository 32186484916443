import React, {useEffect} from 'react'
import nature from "../../Images/nagalandnature.jpg";
import AOS from "aos";
import "aos/dist/aos.css";
import MetaHead from '../../Component/Common/MetaHead';

export default function Index() {
     // aos animation
     useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);
    return (
        <MetaHead title='Contact Us' content='Get in touch' description='We would love to hear from you' image='https://digitallibrarynagaland.com/static/media/nagalandnature.7a371b973e3cbba1335d.jpg' url='https://digitallibrarynagaland.com/contact'>

            <section id="contact">
                <div className="nagacontainer">

                    <div className="contact_body">
                        <div className="contact_top">
                            <h5 data-aos="fade-up" data-aos-duration="2000">Get in touch</h5>

                            {/* <h2>We would love to hear from you</h2> */}
                            {/* <p>
                                If you would like to be part of one of the most impressive education
                                and personal development organizations, get in touch now!
                            </p> */}

                            <div className="details" data-aos="fade-up" data-aos-duration="3000">
                                <div className="details_list">
                                    <a href="#">
                                        <i className="fa-solid fa-location-dot" />
                                    </a>
                                    {/* <h6>Location</h6> */}
                                    <p> Kohima <br />Nagaland - 797001</p>
                                </div>
                                <div className="details_list">
                                    <a href="#">
                                        <i className="fa-solid fa-envelope" />
                                    </a>
                                    {/* <h6>Email Us</h6> */}
                                    <p style={{ textDecoration: "underline" }}><a target="_blank" href="mailto:info@digitallibrarynagaland.com">info@digitallibrarynagaland.com</a></p>
                                </div>
                                <div className="details_list">
                                    <a href="#">
                                        <i className="fa-solid fa-phone-volume" />
                                    </a>
                                    {/* <h6>Call Us</h6> */}
                                    <p>Phone: # +91 9876278882</p>
                                </div>
                            </div>
                        </div>
                        <div className="contact_btm">
                            {/* <div className="contact_form">
                                <form action="">
                                    <div className="form_text">
                                        <h2>Send us a message</h2>
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
                                            tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
                                        </p>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                            <label htmlFor="fname" className="name">
                                                First Name
                                            </label>
                                            <input type="text" name="fname" placeholder="First Name" />
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                            <label htmlFor="lname" className="name">
                                                Last Name
                                            </label>
                                            <input type="text" name="lname" placeholder="Last Name" />
                                        </div>
                                    </div>
                                    <label htmlFor="email">Email</label>
                                    <input type="email" name="email" placeholder="Email" />
                                    <label htmlFor="message">Message</label>
                                    <textarea
                                        className="form-control"
                                        id="msg"
                                        rows={3}
                                        placeholder="Message"
                                        defaultValue={""}
                                    />
                                    <button type="submit">Send Message</button>
                                </form>
                            </div>
                            <div className="social_media">
                                <h2>Follow our social media</h2>
                                <p>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia
                                    labore laboriosam obcaecati, voluptatum aliquid explicabo.
                                </p>
                                <a href="#">
                                    <i className="fa-brands fa-facebook-f" />
                                </a>
                                <a href="#">
                                    <i className="fa-brands fa-twitter" />
                                </a>
                                <a href="#">
                                    <i className="fa-brands fa-instagram" />
                                </a>
                                <a href="#">
                                    <i className="fa-brands fa-youtube" />
                                </a>
                            </div> */}



                            <div className='row'>
                                <div className='col-lg-7 col-md-6 col-12'>
                                    <div className='left_arae'>
                                        <img src={nature} className='img-fluid' alt='image' />
                                    </div>
                                    <p style={{fontSize:'10px',fontFamily: 'Montserrat'}}>Photo Credit:<span> Vipin Joseph/unsplash</span></p>
                                </div>
                                <div className='col-lg-5 col-md-6 col-12'>
                                    <div className='right_area'>
                                        <div className='text'>
                                            <p>We would love to hear from you! Please fill out this form and we will get in touch with you shortly</p>
                                        </div>
                                        <form action="" >
                                            <div className="form-group">
                                                <label htmlFor="">Name*</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id=""
                                                    aria-describedby="textHelp"
                                                    placeholder="Your name"
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="">Your email*</label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    id=""
                                                    aria-describedby="emailHelp"
                                                    placeholder="Your email address"
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="">Phone No*</label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    id=""
                                                    // maxlength={10}
                                                    aria-describedby="numberHelp"
                                                    placeholder="Your Phone no"
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="">Message*</label>
                                                <textarea
                                                    className="form-control"
                                                    id=""
                                                    rows={3}
                                                    defaultValue={""}
                                                    placeholder="Enter Your Message"
                                                />
                                            </div>

                                            <div className='contactsubmit_btn' data-aos="fade-up" data-aos-duration="2000">
                                                <button type='btn' className='btn'>Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

            </section>

        </MetaHead>
    )
}
