import React, { useEffect } from 'react'
import { useState } from 'react'
import g from "../../Images/history1.jpg"
import { Link } from 'react-router-dom'
import c from "../../Images/c1.png"
import BreadcrumbsHeader from '../../Component/BreadcrumbsHeader'
import Pagination from '../../Component/Pagination'
import { useDispatch, useSelector } from 'react-redux'
import { getAllBlog } from '../../redux/reducers/BlogSlice'
import moment from 'moment'
import AOS from "aos";
import "aos/dist/aos.css";
import HomeService from '../../Service/HomeService'
import { toast } from 'react-hot-toast'
import MetaHead from '../../Component/Common/MetaHead'


export default function Index() {
    const { blogData, blogLoading } = useSelector(state => state.allBlogReducer);
    const [blogDesc, setBlogDesc] = useState("")
    const dispatch = useDispatch();

    console.log("blogDataff",blogData)

    const loop = [
        { id: 1, bookName: "This is blog 1", subtitle: "June 25, 2020 | MUSIC | 0 min read | Author: ", text: <span>We have made quality our habit. It’s not something that we just strive for – we live by this principle every day. <br /> While most of the things about the creative process will be forgotten, the excellence of our products and services will be remembered. Read more...</span>, },
        { id: 2, bookName: "This is blog 2", subtitle: "June 25, 2020 | MUSIC | 0 min read | Author: ", text: <span>We have made quality our habit. It’s not something that we just strive for – we live by this principle every day. <br /> While most of the things about the creative process will be forgotten, the excellence of our products and services will be remembered. Read more...</span>, },
        { id: 3, bookName: "This is blog 3", subtitle: "June 25, 2020 | MUSIC | 0 min read | Author: ", text: <span>We have made quality our habit. It’s not something that we just strive for – we live by this principle every day. <br /> While most of the things about the creative process will be forgotten, the excellence of our products and services will be remembered. Read more...</span>, },
        { id: 4, bookName: "This is blog 4", subtitle: "June 25, 2020 | MUSIC | 0 min read | Author: ", text: <span>We have made quality our habit. It’s not something that we just strive for – we live by this principle every day. <br /> While most of the things about the creative process will be forgotten, the excellence of our products and services will be remembered. Read more...</span>, },
    ]

    useEffect(() => {
        dispatch(getAllBlog())
    }, []);

    // aos animation
    useEffect(() => {
        AOS.init();
        AOS.refresh();
        fetchBlogDesc()
    }, []);


    const fetchBlogDesc = () => {
        HomeService.ViewBlogDesc()
            .then((res) => {
                if (res && res?.status) {
                    setBlogDesc(res?.data?.description);
                } else {
                    toast.error(res?.message);
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    return (
        <MetaHead title="Blog" content='Blog Posts' description='Read our curated blogs and explore Nagaland' url='https://digitallibrarynagaland.com/blog'>

            {/* <BreadcrumbsHeader menu="Blog" category="Categories" type="All Items" /> */}



            <section className='bradecum_head' style={{ padding: "70px 0 70px" }}>
                <div className='nagacontainer'>
                    <div className='row'>
                        <div className='col-lg-4 col-md-6 col-12 m-auto'>
                            <div className='head' data-aos="fade-up" data-aos-duration="3000">
                                <h4>Blog Posts</h4>
                            </div>
                        </div>
                        <div className='col-lg-8 col-md-6 col-12 m-auto'>
                            <div className='text' data-aos="fade-up" data-aos-duration="3000">
                                <p>{blogDesc}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="blog">
                <div className="nagacontainer">
                    <div className="blog_body">

                        {/* <div className='headpagination'>
                            <h2>Blog Post</h2>
                        </div> */}

                        {/* <div className="historyleft">
                            <div className="leftcontent">
                                <div className="hist_imgwrapper">
                                    <img src={g} className="card-img-top" alt="img" />
                                </div>
                                <div className="leftcardbody">
                                    
                                    <p className="card-text history_leftpara">
                                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Maxime
                                        quae nulla omnis illum veniam, molestiae reiciendis ratione
                                        officiis suscipit et.
                                    </p>
                                </div>
                                <div className="grey-rule" />
                                <div className="history_feature">
                                    <h5 className="card-title hist_featureH">Featured Posts:</h5>
                                    <a href="" className="hist_featureP">
                                        Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet.
                                    </a>
                                    <a href="" className="hist_featureP">
                                        Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet.
                                    </a>
                                </div>
                                <div className="grey-rule" />
                                <div className="history_icon">
                                    <a href="">
                                        <i className="fa-brands fa-square-facebook" />
                                    </a>
                                    <a href="">
                                        <i className="fa-brands fa-square-instagram" />
                                    </a>
                                    <a href="">
                                        <i className="fa-brands fa-twitter" />
                                    </a>
                                    <a href="">
                                        <i className="fa-brands fa-linkedin" />
                                    </a>
                                </div>
                                <p className="webflow">
                                    BUILT WITH{" "}
                                    <a href="" className="webflow_a">
                                        WEBFLOW
                                    </a>
                                </p>
                            </div>
                        </div> */}

                        <div className="blog_right">
                            <div className="rightlist" data-aos="fade-up" data-aos-duration="3000">

                                {blogData && blogData?.map((item, i) => {
                                    return (
                                        <Link to={`/blog-details/${item?.slug}/${item?._id}`} key={i}>
                                            <div className="right_listitem">
                                                <div className="right_listimg">
                                                    <img src={item?.blogImage} alt="img" />
                                                </div>
                                                <div className="right_listtext">
                                                    <h4 className="history_head">{item?.blogTitle}</h4>
                                                    <p className="history_date">
                                                        <span>{moment(item?.createdOn).format("LL") + " "} | </span>
                                                        <span>{" " + item?.categoryData?.name + " "} | </span>
                                                        <span> {item?.readingTime} min read | </span>
                                                        <span> Author:{" " + item?.author} </span>
                                                    </p>
                                                    {/* <p className="history_para">{item?.description}</p> */}
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: item?.summary,
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Link>
                                    )
                                })}

                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* <Pagination /> */}
        </MetaHead>
    )
}
