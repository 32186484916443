import { Helmet } from "react-helmet";

const MetaHead = ({
  title = "Digital Library Nagaland",
  content = "Nagaland State Archives",
  description = "Explore the rich history and culture of Nagaland through its state archives.",
  image = "https://digitallibrarynagaland.com/Nagaland_Thumbnail_Image.jpg",
  url = "https://digitallibrarynagaland.com/",
  type = "website",
  children
}) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={content} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="og:url" content={url} />
        <meta property="og:type" content={type} />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={content} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />

        {/* <meta property="og:title" content="Nagaland State Archives" />
        <meta property="og:description"
          content="Explore the rich history and culture of Nagaland through its state archives." />
        <meta property="og:image" content="https://digitallibrarynagaland.com/Nagaland_Thumbnail_Image.jpg" />
        <meta property="og:url" content="https://digitallibrarynagaland.com/" />
        <meta property="og:type" content="website" /> */}
      </Helmet>
      {children}
    </>
  );
};

export default MetaHead;