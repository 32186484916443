import React, { useEffect, useState } from "react";
import "./angami.css";
import "./volume_1.css";
import Card from "./Card";
import card_img1 from "../../Images/card-img.png";
import card_img2 from "../../Images/cardimg2.png";
import card_img3 from "../../Images/cardimg3.png";
import DropDown from "./DropDown";
import { Link, useLocation, useParams } from "react-router-dom";
import HttpClient from "../../utils/HttpClient";
import moment from "moment/moment";
import MetaHead from "../../Component/Common/MetaHead";

const Angami = () => {
  const arr = [
    {
      title: "Title of the folklore",
      desc: "Lectus luctus neque donec semper sed a nisl. Sem pulvinar turpis scelerisque mi sapien tellus. Nibh metus et amet mauris interdum velit. Urna in orci adipiscing facilisis.",
      image: card_img1,
    },
    {
      title: "Title of the folklore",
      desc: "Lectus luctus neque donec semper sed a nisl. Sem pulvinar turpis scelerisque mi sapien tellus. Nibh metus et amet mauris interdum velit. Urna in orci adipiscing facilisis.",
      image: card_img2,
    },
    {
      title: "Title of the folklore",
      desc: "Lectus luctus neque donec semper sed a nisl. Sem pulvinar turpis scelerisque mi sapien tellus. Nibh metus et amet mauris interdum velit. Urna in orci adipiscing facilisis.",
      image: card_img3,
    },
  ];

  const params = useParams()
  const location = useLocation()
  const volumeDetail = location?.state?.volumeDetail
  const [singleTribe, setSingleTribe] = useState({});

  // pagination
  const [pageOffset, setPageOffset] = useState(0);
  const [paginatedItems, setPaginatedItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  // const pageCount = Math.ceil(items.length / noOfItemsPerPage);
  const noOfItemsPerPage = 10;

  //** pagination** end

  // console.log("paginatedItemsdd", paginatedItems)

  // get single tribe
  const getSingleTribe = async () => {
    const res = await HttpClient.requestData("tribe-get-single/" + params?.id, {}, "GET")
    if (res && res?.status) {
      setSingleTribe(res?.data);
      const folkData = res?.data?.folkLoreCollections;
      setPaginatedItems(folkData?.slice(0, noOfItemsPerPage))
      setPageCount(Math.ceil(folkData?.length / noOfItemsPerPage))
    } else {
      console.log(res?.message)
    }
  }

  // pagination page change
  const handlePageChange = (num) => {
    window.scrollTo({
      top: 110,
      left: 110,
      behavior: "smooth",
    })

    // console.log("etrr", e);
    const items = singleTribe?.folkLoreCollections
    const currPage = num
    setPageOffset(currPage);

    const initialIndex = currPage * noOfItemsPerPage;
    const lastIndex = currPage * noOfItemsPerPage + noOfItemsPerPage;
    const parPageItem = items?.slice(initialIndex, lastIndex);
    setPaginatedItems(parPageItem);
  }

  // pagination numbers
  const paginationNumbers = () => {
    let arr = []
    {
      for (let i = 1; i <= pageCount; i++) {
        arr.push(i)
      }
    }
    return arr
  }

  useEffect(() => {
    getSingleTribe()
    setPageOffset(0)
  }, [params?.id])


  // console.log("singleTribe: ", singleTribe);
  // console.log("location: ", location?.pathname);
  
  

  return (
    <MetaHead title={`Single-Tribe-${singleTribe?.name}`} content={singleTribe?.photoCopyRight} description={singleTribe?.description} image={singleTribe?.url} url={`https://digitallibrarynagaland.com/${location?.pathname}`}>

      {/* breadcumb */}
      <section className="Angami-section">
        <div className="nagacontainer">

          {/* <div className="folklore-vol angami-vol">
            <Link to="/folklore-new">
              <span>Folklore </span>
            </Link>
            {volumeDetail?.title &&
              <Link to={"/folklore-Volume/" + volumeDetail?._id}>
                <span>
                  <i class="fa-solid fa-greater-than"></i> {volumeDetail?.title}
                </span>
              </Link>
            }
            <span className="vol-span">
              <i class="fa-solid fa-greater-than"></i>{singleTribe?.name}
            </span>
          </div> */}

          <div className="breadcrumbs-header">
            <nav>
              <Link to="/folklore-new">
                Folklore
                <i className="fa-solid fa-angle-right" />
              </Link>

              {volumeDetail?.title &&
                <Link to={"/folklore-Volume/" + volumeDetail?._id} style={{ color: "rgb(0, 0, 0)" }}>
                  {volumeDetail?.title}
                  <i className="fa-solid fa-angle-right" />
                </Link>
              }

              <Link to="#" style={{ color: "rgb(0, 0, 0)" }}>
                {singleTribe?.name}
              </Link>

            </nav>
          </div>



          <div className="angami-main">
            <p className="volume-title">{singleTribe?.name}</p>
            {/* <p className="volume-desc">
              Lorem ipsum dolor sit amet consectetur. Dignissim urna sit feugiat
              quis id.{" "}
            </p> */}
          </div>
        </div>
      </section>


      <section className="angami-main-section">
        <div className="nagacontainer">
          <div className="main-box">

            {/* dropdown part */}
            <div className="angami-main-left">
              <DropDown tribeId={params?.id} />
            </div>


            <div className="angami-main-right">
              <p className="main-right-title">{singleTribe?.name} Folklore</p>

              <div className="main-right-box">
                {
                  singleTribe?.folkLoreCollections?.length === 0 &&
                  <div className="d-flex justify-content-center ">No Data Found</div>
                }

                {/* {singleTribe?.folkLoreCollections?.map((ele, index) => { */}
                {paginatedItems?.map((ele, index) => {
                  let { name, desc, image1 } = ele
                  return (
                    <Link key={index} to={"/folklore-collection/" + ele?._id}>
                      {/* <Card
                        title={title}
                        desc={desc}
                        image={image}
                      /> */}

                      <div className='anagami-card'>
                        <div className='card-main-box'>
                          {/* <div className='card-img-div'>
                            <img src={image1} alt='img' />
                          </div> */}
                          <div className='card_details_page'>
                            <p className='card-date'>{moment(ele?.createdAt).format("LL")}</p>
                            <p className='card-anagami-title'>{name}</p>
                            <p className='card-description'>
                              {/* <span className='card-time'><i className="fa-regular fa-clock"></i>2 minutes Read</span> */}
                              <span className='card-address'><i className="fa-solid fa-location-dot"></i>{ele?.village},Nagaland</span>
                            </p>
                            <p className='card-anagami-desc'>{ele?.smallDescription}</p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                })}


              </div>



              {/* pagination */}
              <div className="angami-pagination">

                {
                  singleTribe?.folkLoreCollections?.length !== 0 &&
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (pageOffset > 0) {
                        handlePageChange(pageOffset - 1)
                      }
                    }}
                  >
                    <i className="fa-solid fa-arrow-left"></i>
                    <p className="pagination-text">Previous</p>
                  </span>
                }

                <ul className="pagination-list">
                  {
                    paginationNumbers()?.map(item =>
                      <li
                        key={item}
                        style={{ cursor: "pointer" }}
                        className={pageOffset === item - 1 ? "active" : ""}
                        onClick={() => handlePageChange(item - 1)}
                      >
                        {item}
                      </li>
                    )
                  }
                  {/* <li className="active">3</li>
                  <li>4</li> */}
                </ul>

                {
                  singleTribe?.folkLoreCollections?.length !== 0 &&
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (pageOffset < paginationNumbers()?.length - 1) {
                        handlePageChange(pageOffset + 1)
                      }
                    }}
                  >
                    <p className="pagination-text">Next</p>
                    <i className="fa-solid fa-arrow-right"></i>
                  </span>
                }
              </div>


            </div>
          </div>
        </div>
      </section>


    </MetaHead>
  );
};

export default Angami;
