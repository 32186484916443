import React from 'react'
// import folkloreImg from "../../Images/add-a-heading-YbNZ6NPB53Tw4JBE.avif"
import folkloreImg from "../../Images/Folklore-banner-new.jpeg"
import { Link } from 'react-router-dom'

function FolkloreNagaland() {
  return (
    
    <>
      <section className="folklore_nagaland">
        <div className="nagacontainer">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-12">
              <div className="leftara">
                <h3 class="feco">Explore the Rich Folklore<br/> of Nagaland</h3>
                <p className="fact">
                  Nagaland's folklore is a treasure trove of wisdom, cultural identity, and historical narratives. It
                  reflects the deep-rooted beliefs, practices, and the ever-evolving traditions of its diverse tribal
                  communities. As Nagaland continues to modernize, preserving and sharing these rich stories is
                  essential in maintaining the unique cultural heritage of the region.
                </p>
                <div className="seemore_div aos-init folklgBtn"
                //  data-aos="fade-up"
                //   data-aos-duration="3000"
                >
                  {/* <Link to="/">Releasing soon!</Link> */}
                  <Link to="/folklore-new">Explore</Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div className="rightarea">
                <figure className="folklore_img">
                  <img src={folkloreImg} className="img-fluid" alt="....." />
                </figure>
              </div>
              <div className="seemore_div aos-init folkmbBtn">
                <Link to="/folklore-new">Explore</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FolkloreNagaland