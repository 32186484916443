import React, { useState } from "react";
import HomeService from "../../Service/HomeService";
import { toast } from "react-hot-toast";
import { FaEye, FaEyeSlash } from "react-icons/fa";

function PasswordChangeModal(props) {
  const { closemodal } = props;
  const initChangePassData = {
    oldpass: "",
    newpass: "",
    confirmPass: "",
  };
  const [changePassData, setChangePassData] = useState(initChangePassData);
  const [formError, setFormError] = useState({});
  const [showpass, setShowPass] = useState(false);
  const [showpass1, setShowPass1] = useState(false);
  const [showpass2, setShowPass2] = useState(false);

  // console.log("formErrorPass", formError)

  const handleChangePass = (e) => {
    const { name, value } = e.target;
    setChangePassData((prev) => {
      return { ...prev, [name]: value };
    });
  };
  const handlepassShow = (e) => {
    e.preventDefault();
    setShowPass((prevShow) => !prevShow);
  };
  const handlepassShow1 = (e) => {
    e.preventDefault();
    setShowPass1((prevShow1) => !prevShow1);
  };
  const handlepassShow2 = (e) => {
    e.preventDefault();
    setShowPass2((prevShow2) => !prevShow2);
  };

  const validate = (data) => {
    let error = {};
    if (!data.oldpass) {
      error.oldpass = "Old Password is required";
    } else if (data.oldpass.length < 6) {
      error.oldpass = "Password should be atleast 6 characters";
    }

    if (!data.newpass) {
      error.newpass = "New Password is required";
    } else if (data.newpass.length < 6) {
      error.newpass = "Password should be atleast 6 characters";
    }

    if (!data.confirmPass) {
      error.confirmPass = "Confirm new password is required";
    } else if (data.newpass != data.confirmPass) {
      error.confirmPass =
        "Confirm New Password “ must be equal to “ New Password";
    }

    setFormError(error);
    return error;
  };

  const handleClickePass = async (e) => {
    e.preventDefault();
    if (Object.keys(validate(changePassData)).length) {
      return;
    }

    const sendData = {
      oldpass: changePassData.oldpass,
      newpass: changePassData.newpass,
    };

    const res = await HomeService.changePassword(sendData);
    console.log("resChange", res);
    if (res && res?.status) {
      toast.success("Your password has been changed successfully");
      setChangePassData(initChangePassData);
      closemodal(false);
    } else {
      toast.error(res?.message);
    }
  };

  return (
    <>
      <section className="passwordChange_modal">
        <div className="modal_wrap">
          <div className="close_btn">
            <button className="btn" onClick={() => closemodal(false)}>
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>

          <div className="content_body">
            <div className="head">
              <h4>Change Password</h4>
            </div>

            <form>
              {/* <div className="form-group">
                                <label htmlFor="">Email address</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id=""
                                    aria-describedby=""
                                    placeholder="Enter email"
                                />
                            </div> */}

              <div className="form-group">
                <label htmlFor="">Old Password</label>
                <div className="d-flex">
                  <input
                    type={showpass ? "text" : "password"}
                    className="form-control"
                    id=""
                    placeholder="Password"
                    name="oldpass"
                    value={changePassData.oldpass}
                    onChange={(e) => handleChangePass(e)}
                  />
                  <button className="eyecon" onClick={handlepassShow}>
                    {showpass ? <FaEye /> : <FaEyeSlash />}
                  </button>
                </div>
                <span style={{ color: "red", fontSize: "15px" }}>
                  {formError.oldpass ? formError.oldpass : null}
                </span>
              </div>

              <div className="form-group">
                <label htmlFor="">New Password</label>
                <div className="d-flex">
                  <input
                    type={showpass1 ? "text" : "password"}
                    className="form-control"
                    id=""
                    placeholder="Password"
                    name="newpass"
                    value={changePassData.newpass}
                    onChange={(e) => handleChangePass(e)}
                  />
                  <button className="eyecon" onClick={handlepassShow1}>
                    {showpass1 ? <FaEye /> : <FaEyeSlash />}
                  </button>
                </div>
                <span style={{ color: "red", fontSize: "15px" }}>
                  {formError.newpass ? formError.newpass : null}
                </span>
              </div>

              <div className="form-group">
                <label htmlFor=""> Confirm New Password</label>
                <div className="d-flex">
                  <input
                    type={showpass2 ? "text" : "password"}
                    className="form-control"
                    id=""
                    placeholder="Password"
                    name="confirmPass"
                    value={changePassData.confirmPass}
                    onChange={(e) => handleChangePass(e)}
                  />
                  <button className="eyecon" onClick={handlepassShow2}>
                    {showpass2 ? <FaEye /> : <FaEyeSlash />}
                  </button>
                </div>
                <span style={{ color: "red", fontSize: "15px" }}>
                  {formError.confirmPass ? formError.confirmPass : null}
                </span>
              </div>

              <button
                type="submit"
                className="btn submit-btn"
                onClick={(e) => handleClickePass(e)}
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}

export default PasswordChangeModal;
