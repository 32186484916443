import React from "react";
import { Link } from "react-router-dom";
import logo from "../Images/Nagaland logo 2 (2).png";
import footerlogo from "../Images/icon1 (2).png";
import map from "../Images/map.png";
import k from "../Images/kkhm.jpeg";
import k1 from "../Images/cut.png";
import K2 from "../Images/Nagaland_Digital_Logo22.png";
import ScrollToTopButton from "../Component/ScrollToTopButton";
export default function Footer() {
  return (
    <>
      {/* <section className='footer'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-xl-3 col-lg-3 col-md-3 col-12 footerLogoPart'>

              <div className='boxnew text-center'>
                <h2>Nagaland <br /><span className='fpp'>Digital Library</span></h2>
                <div>
                  <img src={footerlogo} />
                </div>
              </div>

            </div>
            <div className='col-xl-2 col-lg-2 col-md-2 col-12'>
              <div className='box'>

                <ul>
                  <li><Link to="/about">About</Link></li>
                  <li><Link to="/privacy">Legal</Link></li>
                </ul>

              </div>
            </div>
            <div className='col-xl-2 col-lg-2 col-md-2 col-12'>
              <div className='box'>
                <ul>
                  <li><Link to="">Resources</Link></li>
                  <li><Link to="/cookie">Cookie Policy</Link></li>
                  <li><Link to="/terms">Terms of Use</Link></li>
                </ul>
              </div>
            </div>

            <div className='col-xl-2 col-lg-2 col-md-2 col-12'>
              <div className='box'>

                <ul>

                  <li className='text-center'><Link to="/faq">FAQs</Link></li>

                </ul>
              </div>


            </div>

            <div className='col-xl-2 col-lg-2 col-md-2 col-12'>
              <div className='box'>
                <h3 className='text-center'>Social</h3>
                <ul className='text-center'>
                  <li>
                    <a href="#"><i class="fa-brands fa-twitter"></i></a>
                    <a href="#"><i class="fa-brands fa-instagram"></i></a>
                    <a href="#"><i class="fa-brands fa-linkedin"></i></a>
                  </li>

                </ul>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="footerUpdate">
        <div className="nagacontainer footerConflu">
          <div className="row">
            <div
              className="col-lg-3"
              style={{ cursor: "pointer" }}
              onClick={() => window.scroll(0, 0)}
            >
              <div className="footLogoDiv" style={{ margin: '0 auto 20px auto', display: 'table' }}>
                <Link to="/">
                  {" "}
                  <h2 className="nagaFootHead">
                    {/* DIGITAL */}
                    NAGALAND STATE ARCHIVES
                    <br />
                    <span className="digiText">
                      {/* Library Nagaland */}
                      {/* State Archives */}
                    </span>
                  </h2>
                </Link>
                {/* <figure className='footLogoFig'>
                  <img src={footerlogo} />
                </figure> */}
              </div>
            </div>
            <div className="col-lg-9">
              <div className="footRightDiv">
                <ul className="footerRightUl">

                  <li className="footRightLi">
                    <Link to="/terms" className="footRightLiAn">
                      TERMS OF USE
                    </Link>
                  </li>
                  <li className="footRightLi">
                    <Link to="/contact" className="footRightLiAn">
                      FEEDBACK
                    </Link>
                  </li>
                  <li className="footRightLi">
                    <Link to="/contact" className="footRightLiAn">
                      CONTACT US
                    </Link>
                  </li>
                  <li className="footRightLi">
                    <Link to="/faq" className="footRightLiAn">
                      FAQs
                    </Link>
                  </li>
                </ul>
                <div style={{ textAlign: "center" }}>
                  <Link to="/about" className="footRightLiAn big">
                    ABOUT US
                  </Link>
                  {/* <p className="footRightLiAn">Brought to you by:</p>
                  <img
                    src={k}
                    alt=""
                    style={{ width: "117px", height: "95px" }}
                  />
                  <p className="footRightLiAn">
                    Kohima Smart City Development Limited
                  </p> */}
                </div>
                <div className="footRightLogoDiv">
                  <div className="socialFootLogoDiv">
                    <Link to="/" className="footSocialLogo">
                      <i className="fa-brands fa-twitter"></i>
                    </Link>
                    <Link to="/" className="footSocialLogo">
                      <i className="fa-brands fa-facebook"></i>
                    </Link>
                    <Link to="/" className="footSocialLogo">
                      <i className="fa-brands fa-instagram"></i>
                    </Link>
                  </div>
                  <div className="footerRightTextDiv">
                    <Link to="/privacy" className="footerRightLogText">
                      Privacy
                    </Link>
                    <Link to="/Accessibility" className="footerRightLogText">
                      Accessibility
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr style={{ borderTop: '1px solid rgb(235 220 220 / 66%)' }} />
          {/* <hr style={{ borderTop: '1px solid rgb(235 220 220 / 66%)', width: '1220px' }} /> */}
          <div className="row mt-4 bottomFooter g-1" style={{ alignItems: 'center', textAlign: 'center'}}>
            <div className="col-lg-3 col-md-3 col-sm-12">

              <img src={k} alt="" style={{ width: "90px", height: "90px", objectFit: 'cover', borderRadius: '50%', marginBottom: '15px' }} />
              <p className="footRightLiAn">Brought to you by:</p>
              <p className="footRightLiAn">
                Kohima Smart City Development Limited
              </p>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-12">

              <img src={K2} alt="" style={{ width: "90px", height: "90px", objectFit: 'cover', borderRadius: '50%', marginBottom: '15px' }} />
              <p className="footRightLiAn">Powered by Department</p>
              <p className="footRightLiAn">
                of Art and Culture Nagaland
              </p>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12">

              <img src={k1} alt="" style={{ width: "90px", height: "90px", objectFit: 'cover', borderRadius: '50%', marginBottom: '15px' }} />
              <p className="footRightLiAn">In association with</p>
              <p className="footRightLiAn">
                Government of Nagaland
              </p>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
            <p className="footRightLiAn_updated">Copyrights © 2025 Nagaland State Archives/Sitemap</p>
              <p className="footCpyText d-none">
                Copyrights © 2025 Digital Library Nagaland/Sitemap
              </p>
            </div>
          </div>
        </div>
      </section>
      <ScrollToTopButton />
      {/* <div className='bottom-footer'>
        <p>Copyrights © 2023 Nagaland Digital Library/Sitemap
        </p>
      </div> */}
    </>
  );
}
