import React from 'react'
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import MetaHead from '../../Component/Common/MetaHead';
// import aaa from "../../Images/"

function Gallery() {
  const [open, setOpen] = React.useState(false);
  return (
    <MetaHead title='Gallery' content='Take a look at our insights' description='Discover the inspirational gallery we gathered from our community.' url='https://digitallibrarynagaland.com/gallery'>

      <div className='insights_page'>
        <div className='nagacontainer'>
          <div className='head_line'>
            <h2>Take a look at our insights</h2>
            <p>Discover the inspirational gallery we gathered from our community.</p>
          </div>

          <div className='gallery_area'>
            <div className="column">
              <div className='img_wrap img1' onClick={() => setOpen(true)}>
                <img src="https://assets.zyrosite.com/AVLDKzRX1VIVzR2l/blankgrey-Aq2z2zr66eu26pNq.svg" alt='' />
              </div>

              <div className='img_wrap img2' onClick={() => setOpen(true)}>
                <img src="https://assets.zyrosite.com/AVLDKzRX1VIVzR2l/blankgrey-Aq2z2zr66eu26pNq.svg" alt='' />
              </div>

              <div className='img_wrap img3' onClick={() => setOpen(true)}>
                <img src="https://assets.zyrosite.com/AVLDKzRX1VIVzR2l/blankgrey-Aq2z2zr66eu26pNq.svg" alt='' />
              </div>

              <div className='img_wrap img4' onClick={() => setOpen(true)}>
                <img src="https://assets.zyrosite.com/AVLDKzRX1VIVzR2l/blankgrey-Aq2z2zr66eu26pNq.svg" alt='' />
              </div>

              <div className='img_wrap img5' onClick={() => setOpen(true)}>
                <img src="https://assets.zyrosite.com/AVLDKzRX1VIVzR2l/blankgrey-Aq2z2zr66eu26pNq.svg" alt='' />
              </div>

              <div className='img_wrap img6' onClick={() => setOpen(true)}>
                <img src="https://assets.zyrosite.com/AVLDKzRX1VIVzR2l/blankgrey-Aq2z2zr66eu26pNq.svg" alt='' />
              </div>

            </div>
            <div className="column">
              <div className='img_wrap img1' onClick={() => setOpen(true)}>
                <img src="https://assets.zyrosite.com/AVLDKzRX1VIVzR2l/blankgrey-Aq2z2zr66eu26pNq.svg" alt='' />
              </div>
              <div className='img_wrap img2' onClick={() => setOpen(true)}>
                <img src="https://assets.zyrosite.com/AVLDKzRX1VIVzR2l/blankgrey-Aq2z2zr66eu26pNq.svg" alt='' />
              </div>
              <div className='img_wrap img3' onClick={() => setOpen(true)}>
                <img src="https://assets.zyrosite.com/AVLDKzRX1VIVzR2l/blankgrey-Aq2z2zr66eu26pNq.svg" alt='' />
              </div>
              <div className='img_wrap img4' onClick={() => setOpen(true)}>
                <img src="https://assets.zyrosite.com/AVLDKzRX1VIVzR2l/blankgrey-Aq2z2zr66eu26pNq.svg" alt='' />
              </div>
              <div className='img_wrap img5' onClick={() => setOpen(true)}>
                <img src="https://assets.zyrosite.com/AVLDKzRX1VIVzR2l/blankgrey-Aq2z2zr66eu26pNq.svg" alt='' />
              </div>
              <div className='img_wrap img6' onClick={() => setOpen(true)}>
                <img src="https://assets.zyrosite.com/AVLDKzRX1VIVzR2l/blankgrey-Aq2z2zr66eu26pNq.svg" alt='' />
              </div>
            </div>
          </div>

          <Lightbox
            open={open}
            close={() => setOpen(false)}
            slides={[
              { src: "https://assets.zyrosite.com/AVLDKzRX1VIVzR2l/blankgrey-Aq2z2zr66eu26pNq.svg" },
              { src: "https://assets.zyrosite.com/AVLDKzRX1VIVzR2l/blankgrey-Aq2z2zr66eu26pNq.svg" },
              { src: "https://assets.zyrosite.com/AVLDKzRX1VIVzR2l/blankgrey-Aq2z2zr66eu26pNq.svg" },
              { src: "https://assets.zyrosite.com/AVLDKzRX1VIVzR2l/blankgrey-Aq2z2zr66eu26pNq.svg" },
              { src: "https://assets.zyrosite.com/AVLDKzRX1VIVzR2l/blankgrey-Aq2z2zr66eu26pNq.svg" },
              { src: "https://assets.zyrosite.com/AVLDKzRX1VIVzR2l/blankgrey-Aq2z2zr66eu26pNq.svg" },
              
            ]}
          />


        </div>
      </div>

    </MetaHead>
  )
}

export default Gallery