import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import HomeService from "../../Service/HomeService";
import HttpClient from "../../utils/HttpClient";


const initialState = {
    tribeData: [],

    loading: false,
    error: ""
}

export const getAllTribe = createAsyncThunk('folklore_tribe/getFolklore_tribe', async () => {
    const result = await HttpClient.requestData("folklore-get", {}, "GET");
    if (result) {
        return result;
    }
})

const folkLoreSlice = createSlice({
    name: "fetchThematic",
    initialState: initialState,
    reducers: {},
    extraReducers: {
        [getAllTribe.pending]: (state) => {
            state.loading = true;
        },
        [getAllTribe.fulfilled]: (state, action) => {
            state.tribeData = action.payload.data;
            state.loading = false;
        },
        [getAllTribe.rejected]: (state) => {
            state.loading = false;
            state.error = "Eror"
        }
    }
})

export const folkLoreRed = folkLoreSlice.reducer;