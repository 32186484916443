import React from "react";

function Kashocontributor({ singleFolkColl }) {
  const contributors = [
    {
      name: "Kiyevi Kappo",
      age: "75",
      gender: "Male",
      village: "Lazami",
    },
    {
      name: "Kiyevi Kappo",
      age: "75",
      gender: "Male",
      village: "Lazami",
    },
    {
      name: "Kiyevi Kappo",
      age: "75",
      gender: "Male",
      village: "Lazami",
    }
  ];


  return (
    <div className="contributor_main_box">
      <p className="kasho_contri_title">Contributors</p>
      <div className="contri_box">

        <table className="contributor_list">
          <tr>
              <td className="kasho_contri_qus">{singleFolkColl?.narrator && <p className="Contri_person_name">Narrator:</p>}</td>
              <td className="kasho_contri-ans">{singleFolkColl?.narrator && <p className="Contri_person_name">{singleFolkColl?.narrator}</p>}</td>
          </tr>
          <tr>
              <td className="kasho_contri_qus">{singleFolkColl?.age && <p className="Contri_person_age">Age:</p>}</td>
              <td className="kasho_contri-ans">{singleFolkColl?.age && <p className="Contri_person_age">{singleFolkColl?.age}</p>}</td>
          </tr>
          <tr>
              <td className="kasho_contri_qus">{singleFolkColl?.gender && <p className="Contri_person_gender">Gender:</p>}</td>
              <td className="kasho_contri-ans">{singleFolkColl?.gender && <p className="Contri_person_gender">{singleFolkColl?.gender}</p>}</td>
          </tr>
          <tr>
              <td className="kasho_contri_qus">{singleFolkColl?.villagem && <p className="Contri_person_gender">Village:</p>}</td>
              <td className="kasho_contri-ans">{singleFolkColl?.villagem && <p className="Contri_person_gender">{singleFolkColl?.village}</p>}</td>
          </tr>
          <tr>
              <td className="kasho_contri_qus">{singleFolkColl?.jobProfile && <p className="Contri_person_gender">Job Profile:</p>}</td>
              <td className="kasho_contri-ans">{singleFolkColl?.jobProfile && <p className="Contri_person_gender">{singleFolkColl?.jobProfile}</p>}</td>
          </tr>
        </table>


        {singleFolkColl?.extraNarrator?.map((ele, i) => {
          let { narrator, age, gender, village, jobProfile } = ele;
          return (
            
              <table key={i} className="contributor_list">
                  <tr>
                    <td className="kasho_contri_qus"><p className="Contri_person_name">Narrator:</p></td>
                    <td className="kasho_contri-ans"><p className="Contri_person_name">{narrator}</p></td>
                  </tr>
                  <tr>
                    <td className="kasho_contri_qus"><p className="Contri_person_age">Age:</p></td>
                    <td className="kasho_contri-ans"><p className="Contri_person_age">{age}</p></td>
                  </tr>
                  <tr>
                    <td className="kasho_contri_qus"><p className="Contri_person_gender">Gender:</p></td>
                    <td className="kasho_contri-ans"><p className="Contri_person_gender">{gender}</p></td>
                  </tr>
                  <tr>
                    <td className="kasho_contri_qus"><p className="Contri_person_gender">Village:</p></td>
                    <td className="kasho_contri-ans"><p className="Contri_person_gender">{village}</p></td>
                  </tr>
                  <tr>
                    <td className="kasho_contri_qus"><p className="Contri_person_gender">Job Profile:</p></td>
                    <td className="kasho_contri-ans"><p className="Contri_person_gender">{jobProfile}</p></td>
                  </tr>
              </table>
          );
        })}
      </div>
    </div>
  );
}

export default Kashocontributor;
