import React, { useEffect, useState } from "react";
import "./kashopapu.css";
import DropDown from "./DropDown";
import KashoContent from "./KashoContent";
import Kashocontributor from "./Kashocontributor";
import Pagination from "./Pagination";
import Kashopapubanner from "./Kashopapubanner";
import KashopapuCard from "./KashopapuCard";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import HttpClient from "../../utils/HttpClient";
import BackgroundImg from "../../Images/naga_dadu.png";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MetaHead from "../../Component/Common/MetaHead";


function Kashopapu() {
  const tribeCardData = [
    {
      title: "Folklore Title",
      desc: "Eget bolsa ldhs kudgfhsli kgfislg skgdaih ksdhfos facilisis nunc dolor tristique. Mattis purus interdum id laoreet. Consequat sed diam enim.",
    },
    {
      title: "Folklore Title",
      desc: "Eget bolsa ldhs kudgfhsli kgfislg skgdaih ksdhfos facilisis nunc dolor tristique. Mattis purus interdum id laoreet. Consequat sed diam enim.",
    },
    {
      title: "Folklore Title",
      desc: "Eget bolsa ldhs kudgfhsli kgfislg skgdaih ksdhfos facilisis nunc dolor tristique. Mattis purus interdum id laoreet. Consequat sed diam enim.",
    },
    {
      title: "Folklore Title",
      desc: "Eget bolsa ldhs kudgfhsli kgfislg skgdaih ksdhfos facilisis nunc dolor tristique. Mattis purus interdum id laoreet. Consequat sed diam enim.",
    },
    {
      title: "Folklore Title",
      desc: "Eget bolsa ldhs kudgfhsli kgfislg skgdaih ksdhfos facilisis nunc dolor tristique. Mattis purus interdum id laoreet. Consequat sed diam enim.",
    },
    {
      title: "Folklore Title",
      desc: "Eget bolsa ldhs kudgfhsli kgfislg skgdaih ksdhfos facilisis nunc dolor tristique. Mattis purus interdum id laoreet. Consequat sed diam enim.",
    },
    {
      title: "Folklore Title",
      desc: "Eget bolsa ldhs kudgfhsli kgfislg skgdaih ksdhfos facilisis nunc dolor tristique. Mattis purus interdum id laoreet. Consequat sed diam enim.",
    }
  ];



  const settings = {
    dots: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,

        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 695,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };


  const navigate = useNavigate()
  const params = useParams()
  const [singleFolkColl, setSingleFolkColl] = useState({});
  const [mayLikeFolkLore, setMayLikeFolkLorw] = useState([]);
  const [allFolkLore, setAllFolkLore] = useState([]);
  // const [prevNextInd, setPrevNextInd] = useState(0);

  console.log("mayLikeFolklores", singleFolkColl)

  // get single tribe
  const getFolkCollection = async () => {
    const res = await HttpClient.requestData("folklore-collection-single/" + params?.id, {}, "GET")
    if (res && res?.status) {
      setSingleFolkColl(res?.data)
      getSingleTribe(res?.data?.flokloreData?._id)
    } else {
      console.log(res?.message)
    }
  }

  // get single tribe
  const getSingleTribe = async (id) => {
    const res = await HttpClient.requestData("tribe-get-single/" + id, {}, "GET")
    if (res && res?.status) {
      const fltArr = res?.data?.folkLoreCollections?.filter(ele => ele?._id !== params?.id)
      setMayLikeFolkLorw(fltArr);
      setAllFolkLore(res?.data?.folkLoreCollections)
    } else {
      console.log(res?.message)
    }
  }

  // handle next line
  const handleNextLine = () => {
    const obj = allFolkLore?.find((ele) => ele?._id === params?.id)
    const ind = allFolkLore?.indexOf(obj)
    if (ind != -1) {
      if (ind < allFolkLore?.length - 1) {
        const finalObj = allFolkLore?.[ind + 1]
        // setPrevNextInd(ind + 1)
        navigate("/folklore-collection/" + finalObj?._id)
        console.log("obhhnext", obj, ind, finalObj)
      }
    }
  }


  // handle next line
  const handlePrevLine = () => {
    const obj = allFolkLore?.find((ele) => ele?._id === params?.id)
    const ind = allFolkLore?.indexOf(obj)
    if (ind != -1) {
      if (ind > 0) {
        const finalObj = allFolkLore?.[ind - 1]
        // setPrevNextInd(ind - 1)
        navigate("/folklore-collection/" + finalObj?._id)
        console.log("obhhnext", obj, ind, finalObj)
      }
    }
  }


  useEffect(() => {
    getFolkCollection();
  }, [params?.id])

  // console.log("singleFolklore collection: ", singleFolkColl);
  const location = useLocation();
  
  return (
    <MetaHead title={singleFolkColl?.name} description={singleFolkColl?.description} content={singleFolkColl?.smallDescription} image={singleFolkColl?.flokloreData?.url} url={`https://digitallibrarynagaland.com${location?.pathname}`}>
      <div className="kashopapu_section">

        {/* banner */}
        <div className="kashopapu_banner" style={{ backgroundImage: `url("${singleFolkColl?.flokloreData?.url}")` }}>
          <div className="nagacontainer">
            <Kashopapubanner singleFolkColl={singleFolkColl} />
          </div>
        </div>

        {/* copyright text */}
        <div className="FolkCollectionCopyRight">
          {
            singleFolkColl?.flokloreData?.photoCopyRight &&
            <span style={{fontSize: "10px"}}>{singleFolkColl?.flokloreData?.photoCopyRight}</span>
            // <span>© 2013-2023 John Smith Photography</span>
          }
        </div>

        <div className="kasho_main_section">
          <div className="nagacontainer">
            <div className="kashopapu_box">
              <div className="kashopapu-left_box">
                <div className="kasho_dropdown">
                  <DropDown />
                </div>
                <div className="kashopapu_contributor_first">
                  <Kashocontributor singleFolkColl={singleFolkColl} />
                </div>
              </div>
              <div className="kashopapu_right_box">
                {/* <KashoContent /> */}
                {
                  singleFolkColl?.description &&
                  <div dangerouslySetInnerHTML={{ __html: singleFolkColl?.description }}></div>
                }


                {/* <Pagination /> */}
                <div className="pagination_box">
                  <div className="pagination_box_content">
                    {/* disableColor */}
                    <div className="prev_box" onClick={() => handlePrevLine()}>
                      <span className="pagi_arrow">&larr;</span>
                      <span className="page-text">Previous article</span>
                    </div>

                    <div className="next_box active" onClick={() => handleNextLine()}>
                      <span className="page-text">Next article</span>
                      <span className="pagi_arrow">&rarr;</span>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>


        <section className="tribe-section kashpapu_tribe_section">
          <div className="nagacontainer">
            <p className="tribe-title kashpapu_title">You might also Like</p>
            {/* <p className="tribe-mini-title">
              Als bibliotheeklid heb je toegang tot vele digitale bronnen en
              diensten. Er gaat een digitale wereld voor je open.
            </p> */}

            <Slider {...settings} className="YoumightalsoLike_slider" style={{marginTop: "15px"}}>
              {mayLikeFolkLore?.map((ele, i) => {
                return (
                  <KashopapuCard key={i} ele={ele} />
                )
              })}
            </Slider>

          </div>
        </section>
      </div>
    </MetaHead>
  );
}

export default Kashopapu;
