import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import NoDataFound from "../../Helper/NoDataFound";
import BreadcrumbsHeader from "../../Component/BreadcrumbsHeader";
import ReactPlayer from "react-player";
import p from "../../Images/bb.jpg";
import Slider from "react-slick";
import { useState } from "react";
import { toast } from "react-hot-toast";
import PhotoGalleryModal from "../../Component/Modal/PhotoGalleryModal";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const OtherTheme = ({
  singleThematic,
  photoData,
  themeVideo,
  themeDocs,
  themeBook,
}) => {
  const [videoPlay, setVideoPlay] = useState(false);
  const [videoLinkPlay, setVideoLinkPlay] = useState(false);
  const [videosData, setVideosData] = useState({});

  const [model, setModel] = useState(false);
  const [mergeImage, setMergeImage] = useState([]);

  console.log("IMAGEDATA", photoData);

  console.log("themeVideo", themeVideo);
  const loop = [1, 2, 3, 4, 5, 6];
  const settings = {
    dots: false,
    infinite: false,
    autoplay: false,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 695,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const ParticularGallery = (item) => {
    let arr1 = [];
    let arr2 = [];
    photoData.forEach((ele) => {
      if (item?._id === ele?._id) {
        arr1.push(item);
      } else {
        arr2.push(ele);
      }
    });

    setMergeImage([...arr1, ...arr2]);

    setModel(true);
  };

  const settings1 = {
    dots: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  // console.log("singlethemaic?.summary: ", singleThematic?.summary);

  const summaryRef = useRef(null);

  useEffect(() => {
    // Add target="_blank" to all anchor tags in the summary content
    if (summaryRef.current) {
      const anchorTags = summaryRef.current.querySelectorAll("a");
      anchorTags.forEach((anchor) => {
        anchor.setAttribute("target", "_blank");
      });
    }
  }, [singleThematic?.summary]); // Re-run effect when summary changes
  
  return (
    <div>
      <div className="thematicdetails_page">
        <div className="nagacontainer">
          <div className="row mt-2">
            <BreadcrumbsHeader
              breadArr={[
                {
                  name: "Thematic Content",
                  path: "/thematic",
                },
                {
                  name: singleThematic?.name,
                  path: "",
                },
                // "Thematic Content", singleThematic?.name
              ]}
            />
          </div>
          <div className="headofthe_page">
            <h2>{singleThematic?.name}</h2>
          </div>

          {/* <div className='row'>
                        <BreadcrumbsHeader select={select} location={location} menu="Collections" category="History & Heritage" type="Books" />
                    </div> */}
        </div>

        <section className="sammry_sec">
          <div className="nagacontainer">
            <div className="row">
              <div className="col-md-6 col-12">
                <div className="left_aera">
                  <img src={singleThematic?.img} className="img-fluid" alt="" />
                  {/* <img src={p} className="img-fluid" alt="" /> */}
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="right_area">
                  <h6>SUMMARY</h6>
                  <p>
                    {/* {singleThematic?.summary} */}
                    <div
                      ref={summaryRef}
                      dangerouslySetInnerHTML={{
                        __html: singleThematic?.summary,
                      }}
                    />
                  </p>
                  {/* <p className='italic'>A document of the Battle of Kohima with the aim to remember and commemorate those who fought and died, and also to inform future generations of the sacrifices made by those who fought is found <a href='https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/30002/ww2_kohima.pdf' target="_blank">here <i class="fa-solid fa-angles-right"></i></a></p>

                                    <p className='italic'>Read more on the Battles and Imphal as recorded by the national Army Museum in London, UK. <a href='https://www.nam.ac.uk/explore/battle-imphal' target="_blank">here <i class="fa-solid fa-angles-right"></i></a></p>
                                    <p className='italic'>That Great Bitter Battle of Kohima. Read more <a href='https://warfarehistorynetwork.com/that-great-bitter-battle-of-kohima/' target="_blank">here <i class="fa-solid fa-angles-right"></i></a></p>
                                    <p className='italic'>A guide to the Battle of Kohima can be found <a href='https://kohimaeducationaltrust.net/resource-material/documents/kohima-battlefield-guide.pdf' target="_blank">here <i class="fa-solid fa-angles-right"></i></a> Order of Battle. Read more <a href='https://kohimaeducationaltrust.org/resource-material/documents/the-forgotten-battle.pdf' target="_blank">here <i class="fa-solid fa-angles-right"></i></a></p> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* video */}
      <section
        className="feature thematicvideo_sec mt-2"
        style={{ cursor: "pointer", backgroundColor:"rgb(249, 246, 240)" }}
      >
        <div className="nagacontainer">
          <div className="head_line">
            <h2>VIDEOS</h2>
          </div>

          <div className="">
            <Slider {...settings1} className="">
              {themeVideo?.map((vide) => {
                console.log(vide, "pplla");
                return (
                  <div
                    className="cardbox"
                    onClick={() => {
                      setVideosData(vide);
                      if (vide?.uploadCollection) setVideoPlay(true);
                      else if (vide?.link) setVideoLinkPlay(true);
                      else
                        toast.error(
                          "There is no uploadCollection & link is Available"
                        );
                    }}
                  >
                    {/* <Link to={`/types-details/${vide?.slug}`}> */}
                    {
                      vide?.uploadCollection ? (
                        <div className="video_">
                          <video poster controls>
                            <source
                              src={vide?.uploadCollection}
                              type="video/mp4"
                            />
                          </video>
                        </div>
                      ) : (
                        // <div className='video_'>
                        <div>
                          <ReactPlayer
                            className="react-player"
                            controls
                            width="100%"
                            height="13.2rem"
                            // playing={true}
                            url={vide.link}
                            config={{
                              youtube: {
                                playerVars: { showinfo: 1 },
                              },
                              facebook: {
                                appId: "12345",
                              },
                            }}
                          />
                        </div>
                      )
                      // </div>
                    }

                    {/* <iframe className='video'
                                            title='Youtube player'
                                            sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                                            src={vide?.link}>
                                        </iframe> */}

                    <div className="detailsss">
                      <p className="maintext">{vide.title}</p>
                      {/* {vide.desc && <p className="description">{vide.desc}</p>} */}
                      {vide.author && (
                        <p className="author">Author: {vide.author}</p>
                      )}
                    </div>
                    {/* </Link> */}
                  </div>
                );
              })}
            </Slider>

            {videoPlay && (
              <section
                className="videopopup_area"
                onClick={() => {
                  setVideoPlay(false);
                }}
              >
                <div
                  className="wrapbox"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "90%",
                    width: "70%",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <video controls autoPlay loop width="320" height="240">
                    <source
                      src={videosData?.uploadCollection}
                      type="video/mp4"
                    />
                  </video>
                  <button
                    className="close_btn"
                    onClick={() => {
                      setVideoPlay(false);
                    }}
                  >
                    <i className="fa-solid fa-xmark"></i>
                  </button>
                  {/* <h7 style={{ margin:"2px 3px" ,    fontFamily: 'Montserrat',
    fontSize: '13px', padding:"3px 3px"}}>{videosData?.desc}</h7> */}
                  <div
                    style={{
                      padding: "1rem",
                      overflow: "auto",
                      wordWrap: "break-word",
                      width: "100%",
                    }}
                    dangerouslySetInnerHTML={{ __html: videosData?.summery }}
                  >
                    {/* <h7 style={{ color: "red" }} /> */}
                  </div>
                  {/* <div>
                    <h7
                      dangerouslySetInnerHTML={{ __html: videosData?.summery }}
                    />
                  </div> */}
                </div>
              </section>
            )}

            {videoLinkPlay && (
              <section
                className="videopopup_area"
                onClick={() => {
                  setVideoLinkPlay(false);
                }}
              >
                <div
                  className="wrapbox"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "80%",
                    width: "70%",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <div style={{ height: "100%", width: "100%" }}>
                    <ReactPlayer
                      className="react-player"
                      controls
                      width="100%"
                      height="100%"
                      playing={true}
                      url={videosData?.link}
                      config={{
                        youtube: {
                          playerVars: {
                            showinfo: 1,
                          },
                        },
                        facebook: {
                          appId: "12345",
                        },
                      }}
                    />
                  </div>
                  <button
                    className="close_btn"
                    onClick={() => setVideoLinkPlay(false)}
                    style={{ float: "right" }}
                  >
                    <i className="fa-solid fa-xmark"></i>
                  </button>
                  {/* <h7 style={{ margin:"2px 3px" ,    fontFamily: 'Montserrat',
    fontSize: '13px', padding:"3px 3px"}}>{videosData?.desc}</h7> */}
                  <div
                    style={{
                      width: "100%",
                      padding: "1rem",
                      overflowY: "auto",
                      wordWrap: "break-word",
                    }}
                    dangerouslySetInnerHTML={{ __html: videosData?.summery }}
                  >
                    {/* <h7 style={{ color: "red" }} /> */}
                  </div>
                </div>
              </section>
            )}
            {!themeVideo?.length && <NoDataFound />}
          </div>
        </div>
      </section>

      {/***  DOCUMENTS */}

      <section id="books" className="feature">
        <div className="nagacontainer">
          <h3 className="bookshead">DOCUMENTS</h3>
          <div className="">
            <Slider {...settings} className="">
              {themeDocs &&
                themeDocs?.map((item, i) => (
                  <div className="booksdiv" key={i}>
                    <Link to={`/types-details/${item?.slug}`}>
                      <div className="bookimgwrap mydocu">
                        <a href="#!"></a>
                        <a href="#!">
                          <img
                            src={item?.image}
                            className="img-fluid"
                            alt="img"
                          />
                        </a>
                      </div>
                      <div className="booktext">
                        <p
                          className="bookpara"
                          style={{ height: "auto", overflow: "hidden" }}
                        >
                          {item?.title}
                        </p>
                        {/* <p className="bookpara">{item?.desc}</p> */}
                        <p className="bookpara">
                          Author:{" "}
                          <span className="author_name">{item?.author}</span>
                        </p>
                        {/* <p className="bookpara">{item?.subject}</p> */}
                      </div>
                    </Link>
                  </div>
                ))}
            </Slider>
            {!themeDocs?.length && <NoDataFound />}
          </div>
          {/* <div className='mt-3'>
                            <PaginationTheme
                                originalData={themeBook}
                                setPaginateData={setPagiBook}
                            />
                        </div> */}
        </div>
      </section>

      {/* Books section */}
      <section id="books" className="feature" style={{backgroundColor:"rgb(249, 246, 240)"}}>
        <div className="nagacontainer">
          <h3 className="bookshead">BOOKS</h3>
          <div className="">
            <Slider {...settings} className="">
              {themeBook &&
                themeBook?.map((item, i) => (
                  <div className="booksdiv" key={i}>
                    <Link to={`/types-details/${item?.slug}`}>
                      <div className="bookimgwrap mybooks">
                        <a href="#!"></a>
                        <a href="#!">
                          <img
                            src={item?.image}
                            className="img-fluid"
                            alt="img"
                          />
                        </a>
                      </div>
                      <div className="booktext">
                        <p className="bookpara1">{item?.title}</p>
                        <p className="bookpara">{item?.desc}</p>
                        <p className="bookpara">
                          Author:{" "}
                          <span className="author_name">{item?.author}</span>
                        </p>
                        <p className="bookpara">
                          {item?.isFiction ? "Fiction" : "Non-Fiction"}
                        </p>
                      </div>
                    </Link>
                  </div>
                ))}
            </Slider>
            {!themeBook?.length && <NoDataFound />}
          </div>
          {/* <div className='mt-3'>
                            <PaginationTheme
                                originalData={themeBook}
                                setPaginateData={setPagiBook}
                            />
                        </div> */}
        </div>
      </section>

      {/* photograph section */}
      <section id="photograph">
        <div className="nagacontainer">
          <h3 className="photohead">PHOTOGRAPHS</h3>
          <div className="flexwrapcard">
            {/* <Slider {...settings1} className=""> */}
            {photoData &&
              photoData?.map((item, i) => (
                <div
                  className="photocard"
                  key={i}
                  onClick={() => {
                    ParticularGallery(item);
                  }}
                >
                  {/* <Link to={`/more-details/${item?.slug}`}> */}
                  <div className="photoimgwrap bookimgwrap">
                    <img src={item?.image} alt="img" />
                  </div>
                  {/* </Link> */}
                  <div className="phototext">
                    <p className="photopara1">{item?.title}</p>
                    <p className="photopara">{item?.desc}</p>
                    {/* <p className="photopara">Source: </p> */}

                    <p className="photosource">
                      Source:{" "}
                      <a
                        href="https://www.iwm.org.uk/collections/item/object/205022020"
                        // href={item?.text}
                        target="_blank"
                        className="photolink"
                      >
                        {item?.text}
                      </a>
                    </p>
                  </div>
                </div>
              ))}
            {/* </Slider> */}
            {!photoData?.length && <NoDataFound />}
          </div>
        </div>
      </section>

      {model && (
        <PhotoGalleryModal closemodal={setModel} galleryData={mergeImage} />
      )}
    </div>
  );
};

export default OtherTheme;
