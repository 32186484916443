import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import a from "../../Images/c2.png";
import b from "../../Images/c3.png";
import c from "../../Images/c1.png";
import d from "../../Images/c4.png";
import e from "../../Images/c5.png";
import f from "../../Images/c6.png";
import BreadcrumbsHeader from "../../Component/BreadcrumbsHeader";
import HttpClient from "../../utils/HttpClient";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { async } from "q";
import { useDispatch, useSelector } from "react-redux";
import { getColCat } from "../../redux/reducers/CollectionCategorySlice";
// import { RotatingLines } from 'react-loader-spinner'
import AOS from "aos";
import "aos/dist/aos.css";
import Pegination from '../../Component/Pagination'
import HomeService from "../../Service/HomeService";
import { toast } from "react-hot-toast";
import MetaHead from "../../Component/Common/MetaHead";
export default function Index(props) {
  const { colCatData } = useSelector((state) => state.colCatReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [collectionDesc ,setCollDesc] = useState("")

  console.log("collecttionCat", colCatData);

  const defaultImage =
    "https://t3.ftcdn.net/jpg/03/34/83/22/360_F_334832255_IMxvzYRygjd20VlSaIAFZrQWjozQH6BQ.jpg";

  const idOnCategory = (ele) => {
    console.log("elementtt", ele);
    navigate(`/category-details/${ele?.slug}`, { state: ele.name });
  };

  const handleCatClick = (ele) => {
    idOnCategory(ele);
    // viewCollectionCat(ele?._id);
  };

  useEffect(() => {
    dispatch(getColCat());
  }, []);

  // aos animation
  useEffect(() => {
    AOS.init();
    AOS.refresh();
    fetchCollectionDesc()
  }, []);



  const fetchCollectionDesc = () => {
    HomeService?.ViewCollectionDesc()
        .then((res) => {
            if (res && res?.status) {
                setCollDesc(res?.data?.description);
            } else {
                toast.error(res?.message);
            }
        })
        .catch((err) => {
            console.log(err);
        })
  }

  return (
    <MetaHead title="Browse By Collections" content="General Collections" description="General collections of content about history, literature, and culture of Nagaland" url="https://digitallibrarynagaland.com/collection">
      <div className="collection">
        <section className="bradecum_head">
          <div
            className="nagacontainer"
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12 m-auto">
                <div className="head">
                  <h4>General Collections</h4>
                </div>
              </div>
              <div className="col-lg-8 col-md-6 col-12 m-auto">
                <div className="text">
                  <p>
                    {collectionDesc}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="nagacontainer">
          {/* <h3 className="colle">Collections</h3> */}
          {/* <p className="para">Explore our collections</p> */}

          {/* <BreadcrumbsHeader option={false} types={false} menu="Collections" /> */}

          {/* {loading && <div className='Skeleton-Loader'>
                        <Skeleton style={{ height: "290px", width: "300px" }} count={2} />
                    </div>} */}
          {/* <div className="row">
                        {loading && <div className="col-lg-4 col-md-4 col-12 mt-4 mb-5 collecDiv">

                            <div ><div className="collec-img">
                                <figure className='collecFig'>
                                    <Skeleton style={{ height: "290px", width: "300px" }} count={7} />
                                </figure>
                            </div>
                            </div>
                        </div>}
                    </div> */}
        </div>

        <section className="battle_Kohima">
          <div className="nagacontainer">
            <div
              className="box_wraper"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              {colCatData?.length &&
                colCatData?.map((ele) => (
                  <div className="box_book">
                    <div onClick={() => handleCatClick(ele)}>
                      <div className="img_area">
                        <img
                          src={ele?.image ? ele?.image : defaultImage}
                          alt={`${ele?.name} image`}
                        />
                      </div>
                      <div className="name">
                        <h4>{ele?.name}</h4>
                      </div>
                      {/* <div className="text">
                        <h6>Lorem ipsum dolor sit amet</h6>
                        <p>{ele?.desc}</p>
                      </div> */}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </section>
      </div>

      {/* <Pegination /> */}
    </MetaHead>
  );
}
