import React, { useEffect, useState } from 'react'
import naga from "../../Images/bok-photo3.jpg"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import TechStartupsNagalandCard from "../../Component/TechStartupsNagalandCard"
import gfhf from "../../Images/history.jpg"
import BreadcrumbsHeader from '../../Component/BreadcrumbsHeader';
import HomeService from '../../Service/HomeService';
import toast from 'react-hot-toast'

function StarupsNagaland({ singleThematic }) {

  const [eduTabData, setEduTabData] = useState([]);
  const [headData, setHeadData] = useState("");
  const [bodySection, setBodySection] = useState({});
  const [sequenceData, setSequenceData] = useState([])

  console.log("sequenceData", sequenceData);
  const [startUpAllData, setStartupData] = useState([]);

  useEffect(() => {
    fetchAllStartupData();
  }, [singleThematic]);

  useEffect(() => {
    console.log("sequenceDataaaa", sequenceData)
    setEduTabData(sequenceData[0]?.data);
    StartupData.length !== 0 && StartupData(sequenceData[0]?._id);
  }, [sequenceData])

  useEffect(() => {
    EduSequenceData();
  }, [startUpAllData])

  // useEffect(()=>{
  //   startUpAllData()
  // },[])

  const EduSequenceData = () => {

    console.log("ALLDATA", startUpAllData, singleThematic);
    let arr = [];
    startUpAllData && startUpAllData.forEach((ele) => {
      singleThematic?.data.forEach((item) => {
        if (item?._id === ele) {
          arr.push(item);
        }
      })
    })
    setSequenceData(arr)

    console.log("SEQUENCEDATA", arr);

  }

  const fetchAllStartupData = () => {
    let arr = [];
    HomeService.ViewAllEducationStartupType("startups-in-nagaland")
      .then((res) => {
        console.log("Response Add STARTUPType", res);
        if (res && res.status) {
          // setBodySection(res?.data)
          res?.data?.forEach(ele => {
            arr.push(ele?.typeName)
          });

          setStartupData(arr);
        } else {
          toast.error(res?.message)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }



  const StartupData = (name) => {
    let data = {
      name: name
    }
    HomeService.ViewStartupBody(singleThematic?.slug, data)
      .then((res) => {
        console.log("Response Add StartupsType", res);
        if (res && res.status) {
          setBodySection(res?.data)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }


  return (
    <>
      <div className="nagacontainer">
        <div className="row mt-2">
          <BreadcrumbsHeader
            breadArr={[
              {
                name: 'Thematic Content',
                path: '/thematic',
              },
              {
                name: singleThematic?.name,
                path: '',
              },
              // "Thematic Content", singleThematic?.name
            ]}
          />
        </div>
      </div>
      <section className="StarupsNagaland_page">
        <div className="nagacontainer">
          <div className="head_line">
            <h2>{singleThematic?.name}</h2>
          </div>

          <div className="row">
            <div className="col-lg-5 col-md-6 col-12">
              <div className="nagaimg">
                <img src={singleThematic?.img} className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-lg-7 col-md-6 col-12">
              <div className="nagatext">
                {/* <p>{singleThematic?.summary}</p> */}
                <p>
                  {/* {singleThematic?.summary} */}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: singleThematic?.summary,
                    }}
                  />
                </p>
              </div>
            </div>
          </div>

          <div class="Startups_tabarea">
            <Tabs>
              <TabList className="tabList">
                {sequenceData?.map((item, i) => (
                  <Tab
                    onClick={() => {
                      StartupData(item?._id);
                      setHeadData(item?._id);
                      setEduTabData(item?.data);
                    }}
                  >
                    {item?._id}
                  </Tab>
                ))}
                {/* <Tab>Tech Startup in Nagaland</Tab>
                <Tab>Startup Support - Nagaland</Tab>
                <Tab>Startup Support - India</Tab>
                <Tab>Opportunities for Social Entrepreneur</Tab>
                <Tab>Entrepreneur Networks</Tab> */}
              </TabList>
              {sequenceData?.map(() => (
                <TabPanel>
                  <div className="content_wrap">
                    {/* <div className='Startups_leftcontent'>
                      <TechStartupsNagalandCard
                        headData={headData}
                        body={bodySection}
                      />
                    </div> */}
                    <div className="Startups_rightcontent">
                      {eduTabData &&
                        eduTabData?.map((item, i) => (
                          <div className="cradbox_wrap" key={i}>
                            <a className="bookimg" target="_blank" href={item?.link}>
                              <img src={item?.image} className="img-fluid" alt="" />
                            </a>
                            <div className="detailss">
                              <div className="name">
                                <h4>
                                  <a  target="_blank" href={item?.link}>
                                    {item?.name}
                                  </a>
                                  {/* {item?.name} */}
                                </h4>
                              </div>
                              <div className="text">
                                <p>{item?.description}</p>
                              </div>
                              {/* <div className="morebtn_">
                          
                                <a className="btn" target="_blank" href={item?.link}>
                                  {item?.link}
                                </a>
                              </div> */}
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </TabPanel>
              ))}
            </Tabs>
          </div>
        </div>
      </section>
    </>
  );
}

export default StarupsNagaland