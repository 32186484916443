import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import aa from "../../Images/bok-photo1.jpg";
import bb from "../../Images/bok-photo2.jpg";
import cc from "../../Images/bok-photo3.jpg";
import dd from "../../Images/bok-photo4.jpg";
import ee from "../../Images/bok-photo5.jpg";
import pattern from "../../Images/pattern.jpg";
import nagapic from "../../Images/bok-photo3.jpg";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AOS from "aos";
import "aos/dist/aos.css";

const ThematicHome = ({ themeticData }) => {

  console.log("themeticData", themeticData);
  const id = "64b5135b9bf064b9897797d4";

  const settings = {
    dots: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 695,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // aos animation
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <div>
      <section className="feature" style={{
        marginTop:'50px'
      }}>
        <div className="nagacontainer conFlu">
          {/* <p className="homeFea">
            Home to Local Collections, Indigenous Literature, art and many more.
          </p> */}
          <div className="collectionTop">
            <div>
              <h3 className="feco">Thematic Collections</h3>
              <p className="detailss">Explore Nagaland's rich heritage in our curated section. Delve into the historic Battle of Kohima, witness the transformative influence of Christianity, and discover locally sourced treasures. Dive into a tapestry of culture, history, and innovation.</p>
            </div>

            <figure className="ptrnFig">
              <img src={pattern} alt="" />
            </figure>
          </div>

          <div className="collection_list">
            <div
              className="collection_top"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <Slider {...settings} className="myOwnSlickSlide">
                {themeticData?.map((item, i) => (
                  <Link
                    to={`/thematic-details/${item?.slug}`}
                    className="imgfc"
                    key={i}
                  >
                    <figure className="imgFig imgONEffect">
                      <img src={item?.img} alt="" />
                      <div className="imgOnHover">
                        <p>{item?.content}</p>
                      </div>
                    </figure>
                    <div className="box" style={{ textAlign: "center" }}>
                      <h5 className="text">{item?.name}</h5>
                    </div>
                  </Link>
                ))}
              </Slider>
            </div>
          </div>

          <div
            className="seemore_div"
          // data-aos="fade-up"
          // data-aos-duration="3000"
          >
            <Link to="/thematic">SEE ALL ITEMS</Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ThematicHome;
