import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import HomeService from "../../Service/HomeService";


const initialState = {
    themeticData: [],
    loading: false,
    error: ""
}

export const getThematic = createAsyncThunk('thematic/getThematic', async () => {
    const result = await HomeService.thematicData();
    if (result) {
        return result;
    }
})

const thematicSlice = createSlice({
    name: "fetchThematic",
    initialState: initialState,
    reducers: {},
    extraReducers: {
        [getThematic.pending]: (state) => {
            state.loading = true;
        },
        [getThematic.fulfilled]: (state, action) => {
            state.themeticData = action.payload.data;
            state.loading = false;
        },
        [getThematic.rejected]: (state) => {
            state.loading = false;
            state.error = "Eror"
        }
    }
})

export const thematicReducer = thematicSlice.reducer;