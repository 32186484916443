import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import logo from "../Images/nagalogo (4).png"
import Sidebar from './Sidebar'
import icon from '../Images/icon1 (2).png'
export default function Header2() {
    const [accountModal, setAccountModal] = useState(false);
    const hankclickModal = () => {
        setAccountModal(!accountModal)
    }
    const openNav = () => {
        if (getComputedStyle(document.querySelector('.Sidebar')).transform === 'matrix(1, 0, 0, 1, -250, 0)') {
            document.querySelector('.Sidebar').style.cssText += 'transform: translate(0, 0);';
            document.documentElement.style.cssText='overflow-y: hidden;';
        } else {
            document.querySelector('.Sidebar').style.cssText += 'transform: translate(-250px, 0);';
            document.documentElement.style.cssText='overflow-y: auto;';
        }
    }
    return (
        <>

            <div className='header2'>

                <div className='bottom-header'>
                    <div className='container-fluid'>
                        <div className='row' style={{ justifyContent: "space-between", alignItems: "center" }}>
                        <div className='col-xl-1 col-lg-1 col-md-1 col-6 toggolebar order2'>
                                <a href="#!" className='hambarger' onClick={openNav}><i class="fa-solid fa-bars-staggered"></i></a>
                            </div>
                            <div className='col-lg-3 col-md-3 col-12 order1'>
                                <div className='left'>
                                    <div className='logo'>
                                        <Link to="/"><img src={logo} alt='...' /></Link>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-12 order4' style={{ display: "flex", alignItems: "center" }}>
                                <div className='right'>
                                    <form action="/search" className="headTwoForm">
                                        <input
                                            type="search"
                                            className=""
                                            name="q"
                                            placeholder="Search the collections"
                                            aria-label="Search the collections"
                                            autoComplete="off"
                                            defaultValue=""
                                        />
                                        <button type="submit" className="PageHeader_searchButton__lDXao">
                                            SEARCH <i class="fa-solid fa-caret-right headTwoSeIcon"></i>
                                        </button>
                                        <i class="fa-solid fa-magnifying-glass searchGlass"></i>
                                    </form>
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-3 col-6 order3'>
                                <div className='left'>
                                    <a href="#" className='redBtn' onClick={hankclickModal}><span><img src={icon} /></span>Contribute</a>
                                </div>
                            </div>
                            {/* <div className='col-lg-12 col-md-12 col-2 order2'>
                                <div className='right'>
                                    <ul className='menu'>
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link to="/collection">Collections</Link></li>
                                        <li><Link to="/partner">Partners</Link></li>
                                        <li><Link to="/blog">Blogs</Link></li>
                                        <li><Link to="/contact">Contact</Link></li>
                                    </ul>
                                    <a href="#" className='hambarger' onClick={openNav}><i class="fa-solid fa-bars-staggered"></i></a>
                                </div>
                            </div> */}
                        </div>

                    </div>
                </div>

                <div className='bottom-header bottomMob' style={{ backgroundColor: "#fff", boxShadow:"0px 1px 5px 0px rgba(0,0,0,.3)" }}>
                    <div className='container-fluid'>

                        <div className='col-xl-12 col-lg-12 col-md-12 col-2 order2'>
                            <div className='menuWrapper'>
                                <div className='navMenu'>
                                    <ul className='menu'>
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link to="/collection">Collections</Link></li>
                                        <li><Link to="/partner">Partners</Link></li>
                                        <li><Link to="/blog">Blogs</Link></li>
                                        <li><Link to="/contact">Contact</Link></li>
                                    </ul>
                                </div>

                                <div className='AthenMenu'>
                                    <ul className='menu'>
                                        <li><Link to="">Login</Link></li>
                                        <li><Link to="">SignUp</Link></li>

                                    </ul>
                                </div>
                            </div>
                            <a href="#" className='hambarger' onClick={openNav}><i class="fa-solid fa-bars-staggered"></i></a>
                        </div>
                    </div>
                </div>

            </div>
            {accountModal &&
                <div className='accountOverlay'>
                    <div
                        tabIndex={-1}
                        role="menu"
                        aria-hidden="false"
                        className="rm-pointers dropdown-menu-lg dropdown-menu dropdown-menu-end show"
                        data-popper-placement="bottom-end"
                        style={{
                            position: "absolute",
                            inset: "55px 14px auto auto",
                            transform: "translate(0px, 44px)",
                            padding: "10px"



                        }}
                    >
                        <p style={{ fontSize: "14px", lineHeight: "20px" }}>Lorem ipsum dolor sit amet, <br />consectetur adipiscing elit. </p>
                        <hr style={{ border: "1px solid #AB1A24" }} />
                        <ul className="nav" style={{ padding: "10px", justifyContent: "center" }}>

                            <li className="nav-item px-2">
                                <a href="#" className="" style={{ color: "#AB1A24" }}>
                                    Login
                                </a>
                            </li>

                            <li className='px-2' style={{ color: "#AB1A24" }}>|</li>
                            <li className="nav-item px-2">
                                <a href="#" className="" style={{ color: "#AB1A24" }}>
                                    <div className="">Sign Up</div>
                                </a>
                            </li>

                        </ul>
                    </div>
                </div>
            }
            <div className='mySide'> <Sidebar /> </div>
        </>
    )
}
