import React, { useEffect } from "react";
import a from "../../Images/purposeabout.jpeg";
import b from "../../Images/nagamap.jpeg";
import bigimg from "../../Images/aboutbanner.jpg";
import AOS from "aos";
import "aos/dist/aos.css";
import MetaHead from "../../Component/Common/MetaHead";

export default function Index() {
  // aos animation
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <MetaHead title="About Us" content="Our Purpose"  description="The Nagaland State Archives takes immense pride in curating an extensive collection of resources authored by local writers, which authentically reflects the cultural and linguistic richness of Nagaland." url="https://digitallibrarynagaland.com/about" image="https://digitallibrarynagaland.com/static/media/purposeabout.ae56871c084501005890.jpeg">
      {/* about intro part */}
      <section id="about_intro">
        <div className="nagacontainer">
          <div className="aboutintro_body">
            <div
              className="aboutintro_top"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <h1 className="introhead">Our Purpose</h1>
            </div>
            <div className="aboutintro_btm">
              <div
                className="intro_text"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                {/* <h3 className="introhead_btm">OUR PURPOSE</h3> */}
                <p className="intropara_btm">
                The Nagaland State Archives takes immense pride in curating an extensive collection of resources authored by local writers, which authentically reflects the cultural and linguistic richness of Nagaland. Our community library serves as a vibrant platform that brings together readers, writers, and researchers, fostering mutual learning and growth. We are deeply committed to the preservation and promotion of Nagaland's literary heritage, and we achieve this by offering free access to a vast array of resources, including books, journals, archives, images, and videos, all of which encapsulate the distinctive stories and perspectives of our people.

                </p>
                <p className="intropara_btm">
                The inception of the Nagaland State Archives was driven by the desire to safeguard the abundant cultural heritage and literature of Nagaland and make it accessible to a global audience. Our core mission revolves around establishing an online platform that not only showcases the diverse culture and history of Nagaland but also provides an inclusive space for local authors and researchers to share their invaluable work with the world.

                </p>
              </div>
              <div
                className="aboutintro_left"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <div className="introimg">
                  <img src={a} alt="img" />
                  <p style={{ fontSize: '10px', fontFamily: 'Montserrat' }}>
                    Photo Credit:<span> Arindam Saha/Unsplash</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* good points part */}
      <section id="about_points">
        <div className="nagacontainer">
          <div
            className="about_pointsbody"
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            <div className="row">
              <div className="col-md-6 col-12">
                <div className="points">
                  <h2 className="abt_pointhead">Our vision</h2>
                  <p className="about_pointpara">
                  To create a resource repository that serves as a catalyst, empowering both communities and individuals with open access to our shared history, culture, and knowledge, nurturing growth and unity.
                  </p>
                </div>

                <div className="points">
                  <h2 className="abt_pointhead">Our Goal</h2>
                  <p className="about_pointpara">
                  Our objective is to create a central repository that systematically collects and categorizes all locally produced original content, encompassing a wide range of formats such as books, audio recordings, videos, photographs, papers, journals, presentations, reports, news articles, and various forms of indigenous literature within the realm of art and culture.
                  </p>
                </div>
              </div>

              <div className="col-md-6 col-12">
                <div className="points">
                  <h2 className="abt_pointhead">Our mission</h2>
                  <p className="about_pointpara">
                  The mission of Nagaland State Archives is to enhance the significance of libraries, safeguard and showcase locally created content, and promote fair access to knowledge for everyone. We actively engage in partnerships and leverage the capabilities of digital technology to drive innovation, ensuring that the library remains a valuable asset for our community. We perceive an opportunity to advocate for the vital role of libraries and cultural heritage organizations in our society.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Overview part */}
      {/* <section id="overview">
        <div className="nagacontainer">
          <div className="overview_body">
            <div className="overview_top">
              <h3 className="ov_tophead">Overview</h3>
            </div>
            <div className="overview_btm">
              <div className="overview_left">
                <div className="overviewimg-wrap">
                  <img src={b} alt="img" />
                </div>
              </div>
              <div className="overview_right">
                <div className="overview_points">
                  <div className="overview_number">10,000 <br /><span>Items</span></div>
                  <div className="overview_text">
                    <h6 className="OV_head">Resources</h6>
                    <p className='ov_para'>
                      We showcase materials to promote local collections, indigenous
                      work, art, culture, etc.
                    </p>
                  </div>
                </div>
                <div className="overview_points">
                  <div className="overview_number">283<br /> <span>Collections</span></div>
                  <div className="overview_text">
                    <h6 className="OV_head">Contributors</h6>
                    <p className='ov_para'>
                      We receive diverse submission from our community members,
                      schools, universities, NGO'S, and government and private
                      entities.
                    </p>
                  </div>
                </div>
                <div className="overview_points">
                  <div className="overview_number">10 Thousand<br /> <span>Total Users</span></div>
                  <div className="overview_text">
                    <h6 className="OV_head">Reach</h6>
                    <p className='ov_para'>
                      From our backyard to the rest of the country, we are
                      connecting to all our users.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="about_bigimg">
        <div className="nagacontainer">
          <div className="img_wrap">
            <img src={bigimg} className="img-fluid" alt="" />
          </div>
          <p style={{fontSize:'10px',fontFamily: 'Montserrat'}}>Photo Credit:<span> Vipin Joseph/unsplash</span></p>
          <div
            className="seemore_div"
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            <a href="/contact">CONTRIBUTE</a>
          </div>

          <div className="text" data-aos="fade-up" data-aos-duration="3000">
            <p>
              Become a part of our journey by contributing your work/support.
            </p>
          </div>
        </div>
      </section>
    </MetaHead>
  );
}
