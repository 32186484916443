import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PasswordChangeModal from "../Component/Modal/PasswordChangeModal";
import { useUserData } from "../View/Context/UserContext";
export default function Sidebar({ close, isITOpen }) {
  const { logout, isLogin } = useUserData();
  const [modalA, setModalA] = useState(false);
  const handleClick = () => {
    setModalA(!modalA);
  };
  const empty = () => {};

  const handleClickDefault = (e) => {
    e.stopPropagation();
  };
  const navigate = useNavigate();
  return (
    <>
      <div
        className={isITOpen ? "nagasidevarBG" : null}
        onClick={() => {
          isITOpen ? close() : empty();
        }}
      >
        <div className="Sidebar" id="mySidebar" onClick={handleClickDefault}>
          <ul className="mobMenu">
            <li
              onClick={() => {
                navigate("/");
                close();
              }}
            >
              <Link>Home</Link>
            </li>
            <li onClick={() => close()}>
              <Link to="/thematic">BROWSE BY THEMES</Link>
            </li>

            <li onClick={() => close()}>
              <Link to="/collection">
                BROWSE BY COLLECTIONS{" "}
                {/* <i
                  className="fa-solid fa-caret-down"
                  style={{ marginLeft: "5px" }}
                ></i> */}
              </Link>
              {/* <ul onClick={() => close()} className="submenu_drop">
                <li>
                  <Link to="/thematic">Thematic</Link>
                </li>
               
              </ul> */}
            </li>
            <li onClick={() => close()}>
              <Link to="/folklore-new">FOLKLORES</Link>
            </li>
            <li onClick={() => close()}>
              <Link to="/blog">Blog</Link>
            </li>
            <li
              onClick={() => {
                navigate("/about");
                close();
              }}
            >
              <Link>About us</Link>
            </li>
            {/* <li><Link to="/partner">Partners</Link></li> */}
            {/* <li onClick={() => close()}>
              <Link to="/contact">Contact</Link>
            </li> */}

            {/* <li onClick={() => close()}><Link to="/">Login</Link></li>
                    <li onClick={() => close()}><Link to="/">Sign Up</Link></li> */}
          </ul>
          <ul className="mobMenu">
            {!isLogin ? (
              <>
                <li onClick={() => close()}>
                  <Link to="/login">
                    <i className="fa-solid fa-user mr-1"></i>Login
                  </Link>
                </li>
                {/* <li onClick={() => close()}>
                  <Link to="/signup">
                    <i className="fa-solid fa-user-plus mr-1"></i>Sign Up
                  </Link>
                </li> */}
              </>
            ) : (
              <>
                <li onClick={() => close()}>
                  <Link onClick={handleClick} style={{ fontSize: "12px" }}>
                    <i className="fa-solid fa-key mr-1"></i>Change Password
                  </Link>
                </li>
                <li onClick={() => close()}>
                  <Link onClick={() => logout()} style={{ fontSize: "12px" }}>
                    <i className="fa-solid fa-right-from-bracket mr-1"></i>Log
                    Out
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
      {modalA && <PasswordChangeModal closemodal={setModalA} />}
    </>
  );
}
