import React from 'react'

export default function Index() {
  return (
    <section className="terms-area section-tb-padding my-5">
    <div className="container">
      <div className="row">
        <div className="col">
          <div className="terms-title">
            <h1>Accessibility Statement</h1>
          </div>

          <div className="terms-content privacy-content">
            <p className="mb-3">
              <strong >Commitment to Accessibility:</strong>{" "}
              The Digital Library of Nagaland is committed to providing an accessible and inclusive online environment for all users, including those with disabilities. We strive to meet or exceed the Web Content Accessibility Guidelines (WCAG) 2.1 Level AA standards.
            </p>
            <div className="mb-3">
              <p className="mb-3">
                <strong >Accessibility Features:</strong>{" "}
                
              </p>
              <ul className="privacy-policy">
                <li>
                  <p className="priVacyP">
                  Alternative Text: We provide alternative text for images and multimedia content to ensure compatibility with screen readers.
                  </p>
                </li>
                <li>
                  <p className="priVacyP">
                  Keyboard Navigation: Our website is designed to be navigable using a keyboard.
                  </p>
                </li>
                <li>
                  <p className="priVacyP">
                  Color Contrast: We maintain a high level of color contrast for readability.
                  </p>
                </li>
                <li>
                  <p className="priVacyP">
                  Text Resizing: You can adjust text size in your browser for better readability.
                  </p>
                </li>
              </ul>
            </div>
            <p className="mb-3">
              <strong >Feedback and Assistance:</strong>{" "}
              If you encounter accessibility barriers on our website, please contact us. We welcome your feedback and will make efforts to address any issues promptly.
            </p>
            <p className="mb-3">
              <strong >Accessibility Statement Updates:</strong>{" "}
              We will periodically review and update our accessibility statement to ensure compliance with evolving standards and feedback from our users.
            </p>
            
       
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}
