import React from 'react'
import ab from "../../Images/p1.png"
import bb from "../../Images/p2.png"
import cb from "../../Images/p3.png"
import db from "../../Images/p4.png"
import eb from "../../Images/takeone.png"
import MetaHead from '../../Component/Common/MetaHead'
export default function Index() {
  return (
    <MetaHead title='Partner Logo' content='Partner' description='Partner' url='https://digitallibrarynagaland.com/partner'>

      <div className="partner">
        <div className="container">
          <div className="main-flx pt-5">
            <div className="sub-flex">
              <img src={ab} className="partner-logo" />
              <h5 className="partner-name">Partner name</h5>
            </div>
            <div className="sub-flex">
              <img src={bb} className="partner-logo" />
              <h5 className="partner-name">Partner name</h5>
            </div>
            <div className="sub-flex">
              <img src={cb} className="partner-logo" />
              <h5 className="partner-name">Partner name</h5>
            </div>
          </div>
          <div className="main-flx">
            <div className="sub-flex">
              <img src={db} className="partner-logo" />
              <h5 className="partner-name">Partner name</h5>
            </div>
            <div className="sub-flex">
              <img src={eb} className="partner-logo" />
              <h5 className="partner-name">Partner name</h5>
            </div>
            <div className="sub-flex">
              <img src={ab} className="partner-logo" />
              <h5 className="partner-name">Partner name</h5>
            </div>
          </div>
          <div className="main-flx">
            <div className="sub-flex">
              <img src={bb} className="partner-logo" />
              <h5 className="partner-name">Partner name</h5>
            </div>
            <div className="sub-flex">
              <img src={cb} className="partner-logo" />
              <h5 className="partner-name">Partner name</h5>
            </div>
            <div className="sub-flex">
              <img src={db} className="partner-logo" />
              <h5 className="partner-name">Partner name</h5>
            </div>
          </div>
          <div className="animation">

            <button className="custom-btn  btn-12">
              <span className='see'>Click!</span>
              <span>Read More  <i className="fa-solid fa-angle-down" style={{ paddingLeft: "5px" }} /></span>
            </button>
          </div>
        </div>
      </div>

    </MetaHead>
  )
}
