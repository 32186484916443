import React, { useEffect, useState } from "react";
import a from "../../Images/angami6.jpg";
import b from "../../Images/angami1.jpg";
import img1 from "../../Images/angami1.jpg";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import BreadcrumbsHeader from "../../Component/BreadcrumbsHeader";
import HomeService from "../../Service/HomeService";
import { toast } from "react-hot-toast";
function FolkloreDetailsPage() {
  const { id } = useParams();

  const [folkloreData, setFolkloreData] = useState([]);
  const [folkLore, setFolkLore] = useState([]);
  const [particularData, setParticularData] = useState();
  const [currentIndex, setCurrentIndex] = useState(1);

  useEffect(() => {
    fetchAllFolkloreCategory();
    fetchParticularFolkloreData();
  }, []);

  useEffect(() => {
    setParticularData(folkloreData[0]);
  }, [folkloreData]);

  const HandleSkip = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % folkloreData.length);
    folkloreData && setParticularData(folkloreData[currentIndex]);
  };

  const fetchAllFolkloreCategory = () => {
    HomeService.ViewFolklore()
      .then((res) => {
        if (res && res?.status) {
          setFolkLore(res?.data);
        } else {
          toast.error(res?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchParticularFolkloreData = () => {
    HomeService?.getFolkloreColl(id)
      .then((res) => {
        setFolkloreData(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <section className="folkloreDetails_Page">
        <div style={{ backgroundColor: "rgb(227 219 216 / 40%)" }}>
          <div className="nagacontainer">
            <div className="row mt-2">
              <BreadcrumbsHeader
                breadArr={[
                  {
                    name: "Folklore",
                    path: "/folklore",
                  },
                  {
                    name: `${id}`,
                    // path: "",
                  },
                  // "Thematic Content", singleThematic?.name
                ]}
              />
            </div>
            <div>
              <div className="space_top">
                <div className="top_area">
                  <div className="left">
                    <div className="name">
                      <div className="marginright">
                        {/* <h3>{particularData?.name}</h3> */}
                        <h3>{particularData?.name}</h3>
                        {particularData?.subtitle !== "" ? (
                          <h6>({particularData?.subtitle})</h6>
                        ) : (
                          <></>
                        )}

                      </div>
                      <div className="">
                        <p>
                          <strong>
                            Tribe: {particularData?.flokloredata?.name}
                          </strong>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="right_area"></div>
                </div>
                <div className="top_area_img">
                  <div className="image">
                    <img src={particularData?.image1} alt="" />
                  </div>
                  <div className="Img_right_area">
                    <div className="top">

                      <div className="d-flex">
                        {particularData?.narrator !== "" ? (
                          <>
                            <p style={{ fontSize: "14px" }}>
                              <strong>Narrator:</strong>
                            </p>
                          </>
                        ) : (
                          ""
                        )}{" "}
                        <p>{particularData?.narrator}</p>
                      </div>
                      <div className="d-flex">
                        {particularData?.age !== "" ? <p>Age:</p> : ""}{" "}
                        <p>{particularData?.age}</p>
                      </div>
                      
                      <div className="d-flex">
                        {particularData?.gender !== "" ? <p>Gender:</p> : ""}{" "}
                        <p>{particularData?.gender}</p>
                      </div>
                      <div className="d-flex">
                        {particularData?.village !== "" ? (
                          <p>Village:</p>
                        ) : (
                          ""
                        )}{" "}
                        <p>{particularData?.village}</p>
                      </div>
                      <div className="d-flex">
                        {particularData?.jobProfile !== "" ? (
                          <p>Job Profile:</p>
                        ) : (
                          ""
                        )}
                        <p>{particularData?.jobProfile}</p>
                      </div>


                      {

                        particularData?.extraNarrator?.length > 0 && (
                          <>
                            {
                              particularData?.extraNarrator?.map((item) => {
                                return (
                                  <>
                                    <div className="d-flex mt-4">
                                      {item?.narrator !== "" ? (
                                        <>
                                          <p style={{ fontSize: "14px" }}>
                                            <strong>Narrator:</strong>
                                          </p>
                                        </>
                                      ) : (
                                        ""
                                      )}{" "}
                                      <p>{item?.narrator}</p>
                                    </div>
                                    <div className="d-flex">
                                      {item?.age !== "" ? <p>Age:</p> : ""}{" "}
                                      <p>{item?.age}</p>
                                    </div>
                                    <div className="d-flex">
                                      {item?.gender !== "" ? <p>Gender:</p> : ""}{" "}
                                      <p>{item?.gender}</p>
                                    </div>
                                    <div className="d-flex">
                                      {item?.village !== "" ? (
                                        <p>Village:</p>
                                      ) : (
                                        ""
                                      )}{" "}
                                      <p>{item?.village}</p>
                                    </div>
                                    <div className="d-flex">
                                      {item?.jobProfile !== "" ? (
                                        <p>Job Profile:</p>
                                      ) : (
                                        ""
                                      )}
                                      <p>{item?.jobProfile}</p>
                                    </div>
                                  </>
                                )
                              })
                            }
                          </>
                        )
                      }



                    </div>
                    {
                      particularData?.Illustrator !== "" ? (
                        <h6 style={{ fontWeight: "700", fontSize: "12px" }}>
                          Illustrator: {particularData?.Illustrator}
                        </h6>

                      ) : (
                        <></>
                      )
                    }




                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {/* <img src={particularData?.image2} alt="Description of the image" style={{padding:'20px'}}/> */}
                  <p
                    style={{ marginTop: "40px" }}
                    dangerouslySetInnerHTML={{
                      __html: particularData?.description,
                    }}
                    className="ck_para"
                  ></p>

                  {/* <p >{particularData?.description} <span><img src={particularData?.image2} alt="" /></span></p> */}

                  {/* <div className="Img_2nd">
                <img src={particularData?.image2} alt="" />
              </div> */}
                </div>
              </div>
              {/* <div className="para">
              <p>
                The tale of Kediu Tsükerü (the falling head) is a tale of two
                popular warriors named ‘Kediu’ from the Zeliang Community and
                ‘Krase’ from Angami Community from Khonoma Village.
              </p>
              <p>
                In olden times, when head hunting was the norm, sometimes the
                warriors would go in groups to conquer another villages while on
                some days, the warriors, especially, a good hunter would even
                set out by himself to other areas to hunt for heads in order to
                keep adding heads he conquered as a symbol of pride.
              </p>
              <p>
                Kediu was a renowned warrior from Zeliang community who usually
                visited the Angami area to head hunt and always ended up with
                succession. Krase, who belonged to Khonoma village, was also a
                renowned warrior from their village. When he heard of the great
                Kediu, and about how he was famous for his head hunting skills,
                he decided to defeat Kediu. One day he set off for the Zeliang
                villages with the aim of chopping Kediu’s head and successfully
                killed him. He then tied his shawl on his shoulder and around
                his waist and put Kediu’s head behind his back. On his return
                home, Krase had to climb a mountain where it is believed there
                was a Living Tree, that had the power to give life. Krase
                stopped there and put his hand on the living tree for support
                while he took rest, during which time, Kediu’s head miraculously
                came back to life and bit his back which made Krase take ahold
                of his dao to get rid of the biting. He hit Kediu’s head with
                the tail of the dao which made the head fall from the top of the
                mountain and kept rolling till it reached the bottom of the
                hill.
              </p>
            </div> */}
              {/* 
            <div className="Img_2nd_part">
              <div className="Img_2nd">
                <img src={b} alt="" />
              </div>
              <div className="para">
                <p>
                  The mountain is today called “Kediu Tsükerü” ‘tsü’ meaning
                  ‘head’ and ‘rü’ meaning ‘roll/ falling’ in Khonoma’s dialect.
                </p>
                <p>
                  Kediu mother’s after hearing the news of how her son was
                  killed by another warrior thought to herself “I thought my son
                  was the greatest warrior, but there seems to be a much greater
                  warrior than him.” She wanted to meet this warrior so she wove
                  a Zeliang Shawl and visited Krase, who was drinking his tea.
                  With no word of greeting, she hand the shawl to Krase and
                  said, “I thought my son was good but his defeater is better,
                  but I am afraid you might not live to the expectation in
                  future.” And she returned to her village.
                </p>
              </div>
            </div>

            <div className="para">
              <p>
                One day he went to forest with his ‘pelipfümia’ (lady friend) to
                forage and collect wild edible leaves. There he saw a beautiful
                flower blossoming, so he decided to pluck that flower to give it
                to his ‘pelipfümia’. But as he kept approaching the flower, the
                flower seemed to move farther and farther away, leading Krase
                deep into the forest after which he never returned. The
                villagers searched but no one found him or could trace which way
                he went. Through this it is believed that Kediu’s mother’s
                prophecy came true and Krase ended up not living his full life.
              </p>
            </div> */}
              <div className="ml-auto d-table py-5" onClick={HandleSkip}>
                <a href="#" className="readBtn">
                  Next Read
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="nagacontainer pt-3">
          <div className="row mt-3">
            <div className="col-md-2 col-12">
              <div className="All_link">
                {folkLore?.map((ele, index) => {
                  return (
                    // <Link to={`/folklore-details/${ele?.slug}`}>{ele?.name}</Link>
                    <a href={`/folklore-details/${ele?.slug}`}>{ele?.name}</a>
                  );
                })}
              </div>
            </div>
            <div className="col-md-10 col-12">
              <div>
                <Slider {...settings}>
                  {folkloreData
                    ?.filter((itm) => itm._id !== particularData?._id)
                    .map((item, key) => (
                      <Link key={key} onClick={() => setParticularData(item)}>
                        <div className="box_book">
                          <img
                            src={item?.image1}
                            className="img-fluid"
                            alt="..."
                          />
                          <div className="onImgeText">
                            {/* <h5>Illustrated by {item?.name}</h5> */}
                            <h6>{item?.name}</h6>
                          </div>

                          <div class="overlay"></div>
                        </div>
                      </Link>
                    ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FolkloreDetailsPage;
