import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    archivesState: "Books",
};


const archivesSlice = createSlice({
    name: 'archives/state/Nagaland',
    initialState,
    reducers: {
        set_archives_state: state => {
            state.archivesState = "Documents";
        },
        reset_archives_state: state => {
            state.archivesState = "Books";
        }
    }
});

export const { set_archives_state, reset_archives_state } = archivesSlice.actions;
export const archivesRed =  archivesSlice.reducer;