import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import HomeService from "../../Service/HomeService";


const initialState = {
    blogData: [],
    blogLoading: false,
    singleBlogData: {},
    singleBlogLoading: false
}

export const getAllBlog = createAsyncThunk('blog/getAllBlog', async () => {
    const result = await HomeService.viewAllBlogs();
    if (result) {
        return result;
    }
})

export const getParticularBlog = createAsyncThunk('blog/getParticularBlog', async (id) => {
    const result = await HomeService.viewParticularBlog(id);
    if (result) {
        return result;
    }
})


const featureSlice = createSlice({
    name: "fetchALlBlog",
    initialState: initialState,
    reducers: {},
    extraReducers: {
        [getAllBlog.pending]: (state) => {
            state.blogLoading = true;
        },
        [getAllBlog.fulfilled]: (state, { payload }) => {
            if (payload.status) {
                state.blogData = payload.data;
                state.blogLoading = false;
            } else {
                state.blogData = [];
                state.blogLoading = false;
            }
        },
        [getAllBlog.rejected]: (state) => {
            state.blogLoading = false;
        },

        // particular blog
        [getParticularBlog.pending]: (state) => {
            state.singleBlogLoading = true;
        },
        [getParticularBlog.fulfilled]: (state, { payload }) => {
            if (payload.status) {
                state.singleBlogData = payload.data[0];
                state.singleBlogLoading = false;
            } else {
                state.singleBlogData = [];
                state.singleBlogLoading = false;
            }
        },
        [getParticularBlog.rejected]: (state) => {
            state.singleBlogLoading = false;
        }
    }
})

export const allBlogReducer = featureSlice.reducer;