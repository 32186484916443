import React from "react";
import Slider from "react-slick";
import NoDataFound from "../../Helper/NoDataFound";

const PhotoGalleryModal = (props) => {
  const { closemodal, galleryData } = props;

  console.log("GALLERYDATAgdfgdf", galleryData);

  const settings1 = {
    dots: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <section
        className="commentmodal_area"
        onClick={() => {
          closemodal(false);
        }}
      >
        <div
          className="content_wraps"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="close_btn">
            <button className="btn" onClick={() => closemodal(false)}>
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
          {/* <Slider {...settings1} className="">
          <div className="content_body" style={{ display:"flex" , flexDirection:"row"}}>
           
              {galleryData &&
                galleryData?.map((item, i) => (
                  <div className="booksdiv" key={i}>
                   
                      <div className="bookimgwrap">
                    
                          <img
                            src={item?.image}
                            className="img-fluid"
                            alt="img"
                          />
               
                      </div>
                      <div className="booktext">
                        <p
                          className="bookpara"
                          style={{ height: "auto", overflow: "hidden" }}
                        >
                          {item?.name}
                        </p>
                       
                      </div>
                   
                  </div>
                ))}
          
            
            {!galleryData?.length && <NoDataFound />}
   
          </div>
          </Slider> */}

          <section id="books" className="feature myPhotos">
            <div className="nagacontainer">
              <h3 className="bookshead"></h3>
              <div className="">
                <Slider {...settings1} className="">
                  {galleryData &&
                    galleryData?.map((item, i) => (
                      <div className="booksdivs" style={{}} key={i}>
                        {/* <Link to={`/types-details/${item?.slug}`}> */}
                        <div className="bookimgwraps">
                          <a href="#"></a>
                          <a href="#">
                            <img
                              src={item?.image}
                              className="img-fluid"
                              alt="img"
                            />
                          </a>
                        </div>
                        <div className="booktext">
                          <p
                            className="bookpara"
                            style={{
                              height: "auto",
                              overflow: "hidden",
                              textAlign: "center",
                              fontSize: "13px",
                              fontWeight: "23px",
                            }}
                          >
                            {item?.desc}
                          </p>
                        </div>
                        {/* </Link> */}
                      </div>
                    ))}
                </Slider>
                {!galleryData?.length && <NoDataFound />}
              </div>
              {/* <div className='mt-3'>
                            <PaginationTheme
                                originalData={themeBook}
                                setPaginateData={setPagiBook}
                            />
                        </div> */}
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

export default PhotoGalleryModal;
