import React, { useEffect } from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ThematicHome from './ThematicHome';
import BannerHome from './BannerHome';
import FeatureItesHome from './FeatureItesHome';
import PurposeHome from './PurposeHome';
import ContributeHome from './ContributeHome';
import ContactHome from './ContactHome';
import { getThematic } from '../../redux/reducers/ThemeSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getFeature } from '../../redux/reducers/FeatureReducer';
import FolkloreNagaland from './FolkloreNagaland';
import MetaHead from '../../Component/Common/MetaHead';
import Archive from './Archive';


export default function Index() {

  const thematicRed = useSelector(state => state.thematicReducer);
  const featureRed = useSelector(state => state.featureReducer);
  const dispatch = useDispatch();

  console.log("featureRed", featureRed)

  useEffect(() => {
    dispatch(getThematic());
    dispatch(getFeature());
  }, [])

  return (
    <MetaHead>
      <BannerHome />
      <Archive/>
      <ThematicHome themeticData={thematicRed?.themeticData} />
      <FolkloreNagaland />
      <FeatureItesHome featureData={featureRed?.featureData}  themeCatData = {featureRed?.ThemeCatFeatureData} />
      <PurposeHome />
      <ContributeHome />
      <ContactHome />

      {/* <section className='getInTouch'>
        <div className='nagacontainer conFlu'>
          <h3 className='sia'>GET IN TOUCH</h3>
          <div className='row getInRowClass'>
            <div className='col-md-6 getInColClass topGetInClass'>
              <div>
                <p className='getInTextP'>Digital Library Nagaland</p>
                <p className='getInTextP'>Kohima</p>
                <p className='getInTextP'>Ph. # +91 <span>9876278882</span></p>
                <p className='getInTextP'>Email: <span>info@digitallibrarynagaland.com</span></p>
              </div>
            </div>
            <div className='col-md-6 getInColClass'>
              <form className='getInForm'>
                <div className='getInFrmDiv'>
                  <input placeholder='First name' className="firstNameInp nameInps" />
                  <input placeholder='Last name' className='nameInps' />
                </div>
                <div className='getInFrmDiv'>
                  <input placeholder='email Address' className='getFrmInps' />
                </div>
                <div className='getInFrmDiv'>
                  <textarea rows={6} placeholder='message' className='getFrmInps textAreaInp' />
                </div>
                <div className='getInFrmDiv'>
                  <button className='getSubBtn'>SUBMIT</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section> */}

    </MetaHead>
  )
}
