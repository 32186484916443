import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import logo from "../Images/logo.png";
import icon from "../Images/icon1 (2).png";
import Sidebar from "./Sidebar";
import { useUserData } from "../View/Context/UserContext";
import { getProfile } from "../redux/reducers/ProfileReducer";
import { useDispatch, useSelector } from "react-redux";
import PasswordChangeModal from "../Component/Modal/PasswordChangeModal";

export default function Header() {
  const [modalA, setModalA] = useState(false);
  const [sidebarOpen, setSideBarOpen] = useState(false);
  console.log("sidebarOpen", sidebarOpen);
  const handleClick = () => {
    setModalA(!modalA);
  };

  const profile = useSelector((state) => state.profileReducer);
  const { logout, isLogin } = useUserData();
  const dispatch = useDispatch();

  const [accountModal, setAccountModal] = useState(false);

  const openNav = () => {
    // if (document.getElementById("mySidebar").style.display == "none") {
    //     document.getElementById("mySidebar").style.display = "block";
    //     document.getElementById("main").style.marginLeft = "200px";
    // }
    // else {
    //     document.getElementById("mySidebar").style.display = "none";
    //     document.getElementById("main").style.marginLeft = "0";
    // }

    // alert(getComputedStyle( document.querySelector('.Sidebar')).transform);
    if (
      getComputedStyle(document.querySelector(".Sidebar")).transform ===
      "matrix(1, 0, 0, 1, -250, 0)"
    ) {
      document.querySelector(".Sidebar").style.cssText +=
        "transform: translate(0, 0);";
      // alert(sidebarOpen)
      setSideBarOpen(true);
      // document.documentElement.style.cssText = 'overflow-y: hidden;';
    } else {
      document.querySelector(".Sidebar").style.cssText +=
        "transform: translate(-250px, 0);";
      setSideBarOpen(false);
      // alert("close")
      // document.documentElement.style.cssText = 'overflow-y: auto;';
    }
  };

  const handleClickePass = () => {
    console.log("handleClickePass");
  };

  useEffect(() => {
    dispatch(getProfile());
  }, []);

  //header Sticky
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setIsSticky(offset > 0);
    };

    // Attach the scroll event listener when the component mounts
    window.addEventListener('scroll', handleScroll);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>

      <header className={`header ${isSticky ? 'sticky' : ''}`}>
        <div className="top-header black_header">
          <div className="container-fluid">
            <div className="d-flex align-items-center justi justify-content-end">
              {/* <div className="logo">
                <Link to="/">
                  <img src={logo} className="img-fluid" alt="alt" />
                </Link>
              </div> */}

              <div className="menuWrapper">
                <div className="navMenu">
                  <ul className="menu">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/thematic">BROWSE BY THEMES</Link>
                    </li>
                    <li>
                      <Link to="/collection">
                        BROWSE BY COLLECTIONS
                        {/* <i
                          className="fa-solid fa-caret-down flip"
                          style={{ marginLeft: "5px" }}
                        ></i> */}
                      </Link>
                      {/* <ul className="submenu_drop">
                        <li>
                          <Link to="/thematic">Thematic</Link>
                       
                        </li>
                      </ul> */}
                    </li>
                    {/* <li><Link to="/partner">Partners</Link></li> */}
                    <li>
                      <Link to="/folklore-new">FOLKLORES</Link>
                    </li>
                    <li>
                      <Link to="/blog">Blog</Link>
                    </li>
                    <li>
                      <Link to="/about">About us</Link>
                    </li>
                    {/* <li><Link to="/contact">Contact</Link></li> */}
                  </ul>
                </div>

                <div className="AthenMenu">
                  <ul className="menu">
                    {!isLogin ? (
                      <>
                        <li>
                          <Link to="/login">
                            Login &nbsp; <i class="fa-solid fa-arrow-right-to-bracket"></i>
                          </Link>
                        </li>
                        {/* <li><Link to="/signup"><i className="fa-solid fa-user-plus mr-1"></i>Sign Up</Link></li> */}
                      </>
                    ) : (
                      <>
                        <li>
                          <Link onClick={handleClick}>
                            <i className="fa-solid fa-key mr-1"></i>
                            Change Password
                          </Link>
                        </li>
                        <li>
                          <Link onClick={() => logout()}>
                            <i className="fa-solid fa-right-from-bracket mr-1"></i>
                            Log Out
                          </Link>
                        </li>
                      </>
                    )}
                  </ul>
                  {/* <Link to="/contact" className="redBtn">
                    Contribute
                  </Link> */}
                </div>

                {/* <div className="toggolebar order2">
                  <a href="#" className="hambarger" onClick={openNav}>
                    <i class="fa-solid fa-bars-staggered"></i>
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </header>


      <section className="Header">
        {/* <div className="top-header">
          <div className="container-fluid">
            <div className="row header-wrap">
              <div className="col-xl-1 col-lg-1 col-md-1 col-6 toggolebar order2">
                <a href="#" className="hambarger" onClick={openNav}>
                  <i class="fa-solid fa-bars-staggered"></i>
                </a>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-5 col-12 order1">
                <div className="logo">
                  <Link to="/">
                    <img src={logo} />
                  </Link>
                </div>
              </div>

              <div className="col-xl-5 col-lg-5 col-md-5 col-6 order3">
                <div>
                  <a href="#" className="redBtn" onClick={hankclickModal}>
                    <span>
                      <img src={icon} />
                    </span>
                    Contribute
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div> */}


        <div className="top-header">
          <div className="container-fluid">
            <div className="d-flex align-items-center justi justify-content-between">
              <div className="logo">
                <Link to="/">
                  {/* <img src={logo} className="img-fluid" alt="alt" /> */}
                  <span style = {{
                    color: "white",
                    fontWeight: "600"
                  }}>NAGALAND STATE ARCHIVES</span>
                  <span style={{
                    display: 'none',
                    color: 'white',
                    fontStyle: 'italic',
                    fontSize: '14px',
                    marginLeft: '5px'
                  }}>State Archives</span>
                </Link>
              </div>

              <div className="menuWrapper">
                {/* <div className="navMenu">
                  <ul className="menu">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/about">About us</Link>
                    </li>
                    <li>
                      <Link to="/collection">
                        Collections{" "}
                        <i
                          className="fa-solid fa-caret-down flip"
                          style={{ marginLeft: "5px" }}
                        ></i>
                      </Link>
                      <ul className="submenu_drop">
                        <li>
                          <Link to="/thematic">Thematic</Link>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <Link to="/blog">FOLKLORES Blog</Link>
                    </li>
                  </ul>
                </div>

                <div className="AthenMenu">
                  <ul className="menu">
                    {!isLogin ? (
                      <>
                        <li>
                          <Link to="/login">
                            <i className="fa-solid fa-user mr-1"></i>Login
                          </Link>
                        </li>
                      </>
                    ) : (
                      <>
                        <li>
                          <Link onClick={handleClick}>
                            <i className="fa-solid fa-key mr-1"></i>Change
                            Password
                          </Link>
                        </li>
                        <li>
                          <Link onClick={() => logout()}>
                            <i className="fa-solid fa-right-from-bracket mr-1"></i>
                            Log Out
                          </Link>
                        </li>
                      </>
                    )}
                  </ul>
                  <Link to="/contact" className="redBtn">
                    Contribute
                  </Link>
                </div> */}

                <div className="toggolebar order2 ml-auto">
                  <a href="#!" className="hambarger" onClick={openNav}>
                    <i class="fa-solid fa-bars-staggered"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className='bottom-header bottomMob'>
                    <div className='container-fluid'>

                        <div className='col-xl-12 col-lg-12 col-md-12 col-2 order2'>
                            <div className='menuWrapper'>
                                <div className='navMenu'>
                                    <ul className='menu'>
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link to="/collection">Collections</Link></li>
                                        <li><Link to="/partner">Partners</Link></li>
                                        <li><Link to="/blog">Blogs</Link></li>
                                        <li><Link to="/contact">Contact</Link></li>
                                    </ul>
                                </div>

                                <div className='AthenMenu'>
                                    <ul className='menu'>
                                        <li><Link to="/">Login</Link></li>
                                        <li><Link to="">SignUp</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
      </section>

      {accountModal && (
        <div className="accountOverlay">
          <div
            tabIndex={-1}
            role="menu"
            aria-hidden="false"
            className="rm-pointers dropdown-menu-lg dropdown-menu dropdown-menu-end show"
            data-popper-placement="bottom-end"
            style={{
              position: "absolute",
              inset: "55px 14px auto auto",
              transform: "translate(0px, 44px)",
              padding: "10px",
            }}
          >
            <p style={{ fontSize: "14px", lineHeight: "20px" }}>
              Lorem ipsum dolor sit amet, <br />
              consectetur adipiscing elit.{" "}
            </p>
            <hr style={{ border: "1px solid #AB1A24" }} />

            <ul
              className="nav"
              style={{ padding: "10px", justifyContent: "center" }}
            >
              <li className="nav-item px-2">
                <a href="#!" className="" style={{ color: "#AB1A24" }}>
                  Login
                </a>
              </li>

              <li className="px-2" style={{ color: "#AB1A24" }}>
                |
              </li>
              <li className="nav-item px-2">
                <a href="#!" className="" style={{ color: "#AB1A24" }}>
                  <div className="">Sign Up</div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      )}

      <Sidebar close={openNav} isITOpen={sidebarOpen} />

      {modalA && <PasswordChangeModal closemodal={setModalA} />}
    </>
  );
}
