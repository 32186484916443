import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../Images/logo.png";
import booksimg from "../../Images/signupImg.jpg";
import HomeService from "../../Service/HomeService";
import { reactLocalStorage } from "reactjs-localstorage";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "../../redux/reducers/ProfileReducer";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import MetaHead from "../../Component/Common/MetaHead";

function Login() {
  const initData = {
    email: "",
    password: "",
  };
  const profile = useSelector((state) => state.profileReducer);
  const [formData, setFormData] = useState(initData);
  const [formError, setFormError] = useState({});
  const [showpass, setShowPass] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  console.log("formDatalogin", formData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  // password icon show / hidden
  const handlepassShow = (e) => {
    e.preventDefault();
    setShowPass((prevShow) => !prevShow);
  };

  const validate = (data) => {
    const emaiRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let error = {};

    if (!data.email) {
      error.email = "Email is required";
    } else if (!emaiRegex.test(data.email)) {
      error.email = "Please enter a valid Email";
    }

    if (!data.password) {
      error.password = "Password is required";
    } else if (data.password.length < 6) {
      error.password = "Password should be atleast 6 characters";
    }

    setFormError(error);
    return error;
  };

  const handleLogin = async () => {
    if (Object.keys(validate(formData)).length) {
      return;
    }

    const sendData = {
      email: formData.email,
      password: formData.password,
    };

    // return
    // const res = await HttpClient.requestData("user-register", sendData, "POST");
    const res = await HomeService.userLigin(sendData);
    console.log("regisRes", res);
    if (res && res?.status) {
      toast.success("Logged In Successfully");
      setFormData(initData);
      reactLocalStorage.set("token", res?.data?.token);
      dispatch(getProfile());
      navigate("/");
    } else {
      toast.error(
        "Email or password doesn’t exist OR user is not approved by admin yet"
      );
    }
  };

  // useEffect(() => {
  //     dispatch(getProfile)
  // }, [])

  return (
    <MetaHead title="Login Page" content="Sign In Nagaland Digital" description="Sign In Nagaland Digital" url="https://digitallibrarynagaland.com/login">
      <section className="loginpage_main">
        <div className="nagacontainer">
          {/* <div className="top_wrap">
                        <div className='login_logo'>
                            <Link to="/">
                                <img src={logo} className='img-fluid' alt='alt' />
                            </Link>
                        </div>
                    </div> */}
          <div className="main">
            <div className="left">
              <img src={booksimg} alt="girl-reading-a-book" />
            </div>
            <div className="right">
              <div className="con">
                <form className="sign-up">
                  {/* <h4>Find your next favorite book!</h4> */}
                  <h4>Sign In</h4>

                  {/* <div className="mb-3">
                                        <label htmlFor="text" className="form-label">
                                            Name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="text"
                                            placeholder="Enter your Name"
                                        />
                                    </div> */}

                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="Enter your email"
                      name="email"
                      value={formData.email}
                      onChange={(e) => handleChange(e)}
                    />
                    <span style={{ color: "red", fontSize: "15px" }}>
                      {formError.email ? formError.email : null}
                    </span>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">
                      Password
                    </label>
                    <div className="d-flex">
                      <input
                        type={showpass ? "text" : "password"}
                        className="form-control"
                        id="password"
                        placeholder="Enter your password "
                        name="password"
                        value={formData.password}
                        onChange={(e) => handleChange(e)}
                      />
                      <button className="eyecon" onClick={handlepassShow}>
                        {showpass ? <FaEye /> : <FaEyeSlash />}
                      </button>
                    </div>
                    <span style={{ color: "red", fontSize: "15px" }}>
                      {formError.password ? formError.password : null}
                    </span>
                  </div>

                  <div className="btn submit-btn" onClick={() => handleLogin()}>
                    SIGN IN
                  </div>
                </form>
                <p className="hr-lines"> OR </p>
                {/* <div className="social-sign-up">
                  <button className="btn social">
                    <i
                      className="fa-brands fa-google"
                      style={{ fontSize: "2rem", marginRight: "3.5rem" }}
                    />{" "}
                    Sign in with Google
                  </button>
                </div> */}
                <div className="sign-in">
                  <p>
                    Don't have an account? <Link to="/signup">Sign Up</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </MetaHead>
  );
}

export default Login;
