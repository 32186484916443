import React, { useEffect, useState } from "react";
import "./volume_1.css";
import { Link, useLocation, useParams } from 'react-router-dom';
import card_img1 from "../../Images/card_img_1.png";
import card_img2 from "../../Images/card_img2.png";
import card_img3 from "../../Images/card_img3.png";
import card_img4 from "../../Images/card_img4.png";
import card_img5 from "../../Images/card_img5.png";
import "animate.css";
import TribeCard from "./TribeCard";
import HttpClient from "../../utils/HttpClient";
import MetaHead from "../../Component/Common/MetaHead";
const SingleVolume = () => {
  const tribeCardData = [
    {
      title: "Tribe name",
      image: card_img1,
      desc: "Eget facilisis nunc dolor tristique. Mattis purus interdum id laoreet. Consequat sed diam enim.",
    },
    {
      title: "Tribe name",
      image: card_img2,
      desc: "Eget facilisis nunc dolor tristique. Mattis purus interdum id laoreet. Consequat sed diam enim.",
    },
    {
      title: "Tribe name",
      image: card_img3,
      desc: "Eget facilisis nunc dolor tristique. Mattis purus interdum id laoreet. Consequat sed diam enim.",
    },
    {
      title: "Tribe name",
      image: card_img4,
      desc: "Eget facilisis nunc dolor tristique. Mattis purus interdum id laoreet. Consequat sed diam enim.",
    },
    {
      title: "Tribe name",
      image: card_img5,
      desc: "Eget facilisis nunc dolor tristique. Mattis purus interdum id laoreet. Consequat sed diam enim.",
    },
  ];

  const parram = useParams()
  const [singleVolume, setSingleVolume] = useState({});

  console.log("singleVolumeff", singleVolume)

  const getFolkVolumeSingle = async () => {
    const res = await HttpClient.requestData("folklore-volume-single/" + parram?.id, {}, "GET")
    if (res && res?.status) {
      setSingleVolume(res?.data)
    } else {
      console.log(res?.message)
    }
  }

  useEffect(() => {
    getFolkVolumeSingle()
  }, []);

  const location = useLocation();


  return (
    <MetaHead title="Folklores-Volume" content="Folklore Volume 1" description={singleVolume?.smallDesc} image={singleVolume?.image} url={`https://digitallibrarynagaland.com${location?.pathname}`}>
      <section className="volume-section">
        <div className="nagacontainer">

          {/* <div className="folklore-vol">
            <Link to="/folklore-new">
              <span>Folklore </span>
            </Link>
            <span className="vol-span">
              <i class="fa-solid fa-greater-than"></i>{singleVolume?.title}
            </span>
          </div> */}

          <div className="breadcrumbs-header">
            <nav>
              <Link to="/folklore-new">
                Folklore
                <i className="fa-solid fa-angle-right" />
              </Link>
              <Link to="#" style={{ color: "rgb(0, 0, 0)" }}>
                {singleVolume?.title}
              </Link>
            </nav>
          </div>


          <div className="volume1-main">
            <p className="volume-title">{singleVolume?.title}</p>
            <p className="volume-desc">
              {singleVolume?.smallDesc}{" "}
            </p>
          </div>
        </div>
      </section>
      <section className="acknowledgement-section">
        <div className="nagacontainer">
          <div className="acknowledgement-main">
            <p className="ack-title">Acknowledgement</p>
            {/* <p className="ack-mini-title">
              Lorem ipsum dolor sit amet consectetur.
            </p> */}

            <div className="ack-desc-box">
              {/* <p className="ack-desc-para">
                Lorem ipsum dolor sit amet consectetur. At mauris sit ultricies
                amet in congue laoreet fames mauris. Etiam nunc tincidunt
                egestas blandit bibendum augue donec cursus. Lacus in ut nibh
                aliquam tellus netus. Orci risus neque aliquam et et facilisis
                proin. Curabitur et metus eu nisi mi sagittis.
              </p>
              <p className="ack-desc-para">
                Vitae est felis volutpat nulla molestie id consequat urna. Vel
                amet consequat in eros vel aliquet nibh gravida arcu. Purus
                volutpat in vitae ut egestas duis feugiat venenatis aliquet.
                Enim amet elementum amet tempor. Ornare rutrum ac tempus aenean
                ultrices rhoncus crash.Lorem ipsum dolor sit amet consectetur.
                At mauris sit ultricies amet in congue laoreet fames mauris.
                Etiam nunc tincidunt egestas blandit bibendum augue donec
                cursus. Lacus in ut nibh aliquam tellus netus. Orci risus neque
                aliquam et et facilisis proin. Curabitur et metus eu nisi mi
                sagittis.
              </p>
              <p className="ack-desc-para">
                Lorem ipsum dolor sit amet consectetur. At mauris sit ultricies
                amet in congue laoreet fames mauris. Etiam nunc tincidunt
                egestas blandit bibendum augue donec cursus. Lacus in ut nibh
                aliquam tellus netus. Orci risus neque aliquam et et facilisis
                proin. Curabitur et metus eu nisi mi sagittis.
              </p>
              <p className="ack-desc-para">
                Lorem ipsum dolor sit amet consectetur. At mauris sit ultricies
                amet in congue laoreet fames mauris. Etiam nunc tincidunt
                egestas blandit bibendum augue donec cursus. Lacus in ut nibh
                aliquam tellus netus. Orci risus neque aliquam et et facilisis
                proin. Curabitur et metus eu nisi mi sagittis.Vitae est felis
                volutpat nulla molestie id consequat urna. Vel amet consequat in
                eros vel aliquet nibh gravida arcu. Purus volutpat in vitae ut
                egestas duis feugiat venenatis aliquet. Enim amet elementum amet
                tempor. Ornare rutrum ac tempus aenean ultrices rhoncus crash..
              </p>
              <p className="ack-desc-para">
                Vitae est felis volutpat nulla molestie id consequat urna. Vel
                amet consequat in eros vel aliquet nibh gravida arcu. Purus
                volutpat in vitae ut egestas duis feugiat venenatis aliquet.
                Enim amet elementum amet tempor. Ornare rutrum ac tempus aenean
                ultrices rhoncus crash.
              </p> */}
              <div dangerouslySetInnerHTML={{ __html: singleVolume?.description }}></div>
            </div>
          </div>
        </div>
      </section>


      {/* tribe section */}
      <section className="tribe-section">
        <div className="nagacontainer">
          <p className="tribe-title">Folklore collection by tribes</p>
          <p className="tribe-mini-title">
            {singleVolume?.tribeDesc}
          </p>

          <div className="tribe-main">
            {singleVolume?.tribes?.map((ele, i) => {
              return (
                <div className="tribe-card">
                  <p className="card-title">{ele?.name}</p>
                  <div className="card_img">
                    <div className="card_tagline">
                      <li className="kasho_tagline">Folklore</li>
                      <li className="kasho_tagline">{ele?.name}</li>
                    </div>
                    <img src={ele?.url} className="img-fluid" alt="..." />
                  </div>
                  {/* <p className="card-desc">{ele?.description}</p> */}
                  <div className="vol-btn">
                    <Link className="" to={"/single-tribe/" + ele?._id} state={{ volumeDetail: singleVolume }}>
                      READ MORE
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </MetaHead>
  );
};

export default SingleVolume;
