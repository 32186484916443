import React from "react";
import prev from "../Images/prev-icon.png";
import next from "../Images/next-icon.png";

function Pagination(props) {
  const { page, numberOfPages, setPage } = props;

  return (
    <div className="paginationwrapper">
      <ul className="pagination">
        <li className="page-item"
        style={{ cursor: "pointer" }}
         onClick={() => {
          setPage(page - 1)
          window.scroll(0, 0)
        }}>
          {page && page > 1 && (
            <a className="page">
              <img src={prev} alt="Prev" className="img-fluid" />
            </a>
          )}
        </li>

        {[...Array(numberOfPages)].map((ele, index) => {
          return (
            <li
              className={page === index + 1 ? "page" : "pagestyle"}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setPage(index + 1)
                window.scroll(0, 0)
              }}
            >
              {index + 1}
            </li>
          );
        })}
        <li
          className="page-item"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setPage(page + 1);
            window.scroll(0, 0)
          }}
        >
          {page && page < numberOfPages && (
            <a className="page">
              <img src={next} className="img-fluid" alt="Next" />
            </a>
          )}
        </li>
      </ul>
    </div>
  );
}

export default Pagination;
