import React from 'react'

const NoDataFound = () => {
    return (
        <div className='d-flex justify-content-center mt-3'>
            <h5 style={{ font:"mo"}}>
            Stay tuned. Coming soon!!
            </h5>
        </div>
    )
}

export default NoDataFound
